import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { Fundraiser, FundraiserListDTO } from './model';

export const fundraiserRequest = (): Action => ({
	type: t.FUNDRAISER_REQUEST
});

export const fundraiserSuccess = (fundraiser: Fundraiser): Action => ({
	type: t.FUNDRAISER_SUCCESS,
	data: fundraiser
});

export const fundraiserFailure = (error: Error): Action => ({
	type: t.FUNDRAISER_FAILURE,
	error
});

export const resetFundraiserRequestStatus = (): Action => ({
	type: t.FUNDRAISER_STATUS_RESET
});

export const setFundraiserValues = (fundraiser: Fundraiser): Action => ({
	type: t.SET_FUNDRAISER_VALUES,
	data: fundraiser
});

export const fundraiserListRequest = (): Action => ({
	type: t.FUNDRAISER_LIST_REQUEST
});

export const fundraiserListSuccess = (fundraiserItems: FundraiserListDTO): Action => ({
	type: t.FUNDRAISER_LIST_SUCCESS,
	data: fundraiserItems
});

export const fundraiserListFailure = (error: Error): Action => ({
	type: t.FUNDRAISER_LIST_FAILURE,
	error
});

export const fundraiserMetricRequest = (): Action => ({
	type: t.FUNDRAISER_METRICS_REQUEST
});

export const fundraiserMetricSuccess = (data: any): Action => ({
	type: t.FUNDRAISER_METRICS_SUCCESS,
	data
});

export const fundraiserMetricFailure = (error: Error): Action => ({
	type: t.FUNDRAISER_METRICS_FAILURE,
	error
});
