import flow from 'lodash/flow';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import StripeCheckout from 'react-stripe-checkout';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../../../../../store';
import publicDonationMembers from '../../../../../store/modules/publicDonation';
import { PublicDonationDTO } from '../../../../../store/modules/publicDonation/model';
import { findEnvAttr } from '../../../../../utility/process';

interface Props {
	title?: string;
	logo?: string;
	donate(publicDonation: PublicDonationDTO): void;
	buttonReference: any;
	donationAmount: number;
	fundraiserId: string;
	anonymousDonation: boolean;
	tipValue?: number;
}

const Checkout = (props: Props): ReactElement => {
	const { donate, fundraiserId, donationAmount, tipValue, anonymousDonation } = props;

	const handleDonation = (token: any): void => {
		const donation: PublicDonationDTO = {
			tokenId: token.id,
			email: token.email,
			fullName: token.card.name,
			zipCode: token.card.address_zip,
			amount: donationAmount,
			fundraiserId,
			localTime: moment().format('MMMM Do YYYY, h:mm:ss a'),
			anonymous: anonymousDonation,
			tipValue
		};
		donate(donation);
	};

	// We need to multiple these values by 100, as stripe treats $1.00 as 100.
	// They do this to avoid those nasty floating points
	const stripeCheckoutAmount = donationAmount ? donationAmount * 100 + (tipValue || 0) * 100 : 0;

	return (
		<StripeCheckout
			image={props.logo || 'https://s3.amazonaws.com/assets.4giving.com/logo-transparent.png'}
			name={props.title}
			zipCode={true}
			billingAddress={true}
			amount={stripeCheckoutAmount}
			token={handleDonation}
			stripeKey={findEnvAttr('REACT_APP_STRIPE_KEY')}
		>
			<button id="invis" ref={props.buttonReference} style={{ display: 'none' }}>
				invis
			</button>
		</StripeCheckout>
	);
};

const mapStateToProps = (state: AppState): any => {
	return {
		tipValue: publicDonationMembers.selectors.selectDonationTip(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			donate: publicDonationMembers.thunks.postDonation
		},
		dispatch
	);
};
export default flow([
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Checkout);
