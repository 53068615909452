import { Reducer } from 'redux';

import { Action } from '../../common/typesInterface';
import ActionTypes from './actionTypes';
import { PublicDonationState } from './model';

export const initialState: PublicDonationState = {
	status: {
		error: undefined,
		inFlight: false,
		success: false
	},
	donation: {}
};

const reducer: Reducer<PublicDonationState> = (state = initialState, action: Action): PublicDonationState => {
	switch (action.type) {
		case ActionTypes.PUBLIC_DONATION_REQUEST:
			return {
				...state,
				status: {
					...state.status,
					inFlight: true,
					error: undefined
				}
			};
		case ActionTypes.PUBLIC_DONATION_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				successfulDonation: {
					...action.data
				}
			};
		case ActionTypes.PUBLIC_DONATION_FAILURE:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					error: action.error
				}
			};
		case ActionTypes.PUBLIC_DONATION_CLEAR:
			return { ...initialState };
		case ActionTypes.PUBLIC_DONATION_SET_AMOUNT: {
			return {
				...state,
				donation: {
					...state.donation,
					amount: action.data.amount
				}
			};
		}
		case ActionTypes.PUBLIC_DONATION_SET_TIP: {
			return {
				...state,
				donation: {
					...state.donation,
					tipValue: action.data.tip
				}
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
