import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { login, logout } from '../../../utility/AuthService';
import { AppState } from '../..';
import { loginRequest, logoutSuccess } from './actions';

export const executeLogin = (options?: Auth0LockShowOptions): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch) => {
		dispatch(loginRequest());
		login(options);
	};
};

export const executeLogout = (): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch) => {
		dispatch(logoutSuccess());
		logout();
	};
};
