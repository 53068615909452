import axios from 'axios';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { findEnvAttr } from '../../../utility/process';
import { AppState } from '../..';
import { METHODS } from '../service/model';
import { publicFundraiserFailure, publicFundraiserRequest, publicFundraiserSuccess } from './actions';
import { selectPublicFundraiserRequestInFlight } from './selectors';

export const getPublicFundraiser = (fundraiserId: string): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectPublicFundraiserRequestInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		dispatch(publicFundraiserRequest());
		try {
			const requestOptions = {
				crossDomain: true,
				method: METHODS.GET,
				url: `https://s3.amazonaws.com/sites.4giving.com/${findEnvAttr(
					'REACT_APP_ENVIRONMENT'
				)}/fundraiser/${fundraiserId}.json`
			};
			const response = await axios(requestOptions);
			if (response.data) {
				dispatch(publicFundraiserSuccess(response.data));
			} else {
				dispatch(publicFundraiserFailure(new Error('No fundraiser found')));
			}
		} catch (e) {
			dispatch(publicFundraiserFailure(e));
		}
	};
};
