import get from 'lodash/get';
import React from 'react';

import ActiveDonation from '../../page/public/activeDonation';
import DonationPage from '../../page/public/donationPage';
import Home from '../../page/public/home/index.tsx';
import Onboarding from '../../page/public/onboarding';
import Summary from '../../page/public/summary/index';
import TermsOfService from '../../page/public/tos';
import PublicContainer from '../containers/publicContainer';

export default Object.freeze({
	homePage: {
		route: '/',
		label: 'Home',
		getComponent: () => {
			return (
				<PublicContainer>
					<Home />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/';
		},
		siteMap: true
	},
	signIn: {
		route: '/sign-in',
		label: 'Sign In',
		getComponent: () => {
			return (
				<PublicContainer>
					<Home showSignUp={true} />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/sign-in';
		}
	},
	signUp: {
		route: '/sign-up',
		label: 'Sign Up',
		getComponent: () => {
			return (
				<PublicContainer>
					<Home showLogIn={true} />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/sign-up';
		},
		siteMap: true
	},
	onboarding: {
		route: '/onboarding',
		label: 'Create a new Fundraiser',
		getComponent: () => {
			return (
				<PublicContainer>
					<Onboarding />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/onboarding';
		},
		siteMap: true
	},
	signUpStep: {
		route: '/onboarding/:stepId',
		label: 'Create a new Fundraiser',
		getComponent: props => {
			const stepId = get(props, 'match.params.stepId');
			return (
				<PublicContainer>
					<Onboarding stepId={parseInt(stepId, 10)} />
				</PublicContainer>
			);
		},
		getRoute: stepId => {
			return `/onboarding/${stepId}`;
		},
		siteMap: true
	},
	summary: {
		route: '/summary',
		label: 'Summary',
		getComponent: () => {
			return (
				<PublicContainer>
					<Summary />
				</PublicContainer>
			);
		},
		getRoute: () => {
			return '/summary';
		},
		siteMap: true
	},
	donation: {
		route: '/donation/:fundraiserId',
		label: 'Donation',
		getComponent: props => {
			const fundraiserId = get(props, 'match.params.fundraiserId');
			return (
				<PublicContainer>
					<DonationPage fundraiserId={fundraiserId} />
				</PublicContainer>
			);
		},
		getRoute: fundraiserId => {
			return `/donation/${fundraiserId}`;
		},
		siteMap: false
	},
	activeDonation: {
		route: '/active/:fundraiserId',
		label: 'Active Donation',
		getComponent: props => {
			const fundraiserId = get(props, 'match.params.fundraiserId');
			return <ActiveDonation fundraiserId={fundraiserId} />;
		},
		getRoute(fundraiserId) {
			return `/active/${fundraiserId}`;
		}
	},
	termsOfService: {
		route: '/terms',
		label: 'Terms Of Service',
		getComponent: () => {
			return (
				<PublicContainer>
					<TermsOfService />
				</PublicContainer>
			);
		},
		getRoute() {
			return '/tos';
		}
	}
});
