import * as actions from './actions';
import * as constants from './constants';
import * as model from './model';
import reducer from './reducer';
import * as selectors from './selectors';
import * as thunks from './thunks';

export default {
	actions,
	constants,
	model,
	reducer,
	selectors,
	thunks
};
