import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { Charity } from './model';

export const charityRequest = (): Action => ({
	type: t.CHARITY_REQUEST
});

export const charitySuccess = (charity: Charity[]): Action => ({
	type: t.CHARITY_SUCCESS,
	data: charity
});

export const charityFailure = (error: Error): Action => ({
	type: t.CHARITY_FAILURE,
	error
});
