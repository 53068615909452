import { InputAdornment, List, ListItem, ListItemText, Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import flow from 'lodash/flow';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import NumberFormatCustom from '../../../../../component/inputNumberFormat';
import WizardButtonGroup from '../../../../../component/wizardButtonGroup/wizardButtonGroup';
import { AppState } from '../../../../../store';
import { Charity } from '../../../../../store/modules/charity/model';
import fundraiserMembers from '../../../../../store/modules/fundraiser';
import { FundrasierDTO } from '../../../../../store/modules/fundraiser/model';
import { isSet, useFormInput } from '../../../../../utility/formHelpers';
import { rootNormalInput, textField } from '../../styleDefinitions';
import { FundraiserGoalValidationErrors } from './interfaces';
import NonProfitDialog from './nonProfitSelector';
import { validateFundraiser } from './validations';

interface Props {
	classes: any;
	fundraiser: FundrasierDTO;
	handleBack(): void;
	handleNext(): void;
	saveLocalFundraiser(fundraiser: FundrasierDTO): void;
}

const defaultCharity = { name: 'Enter your non-profit' };

const Goals = (props: Props): ReactElement => {
	const { classes, fundraiser, handleNext, saveLocalFundraiser } = props;
	const [dialogState, toggleDialogState] = useState(false);
	const [charity, setCharity] = useState<Charity>(
		{ name: fundraiser.charityName, ein: fundraiser.charityEin } || defaultCharity
	);

	const [errors, setErrorValue] = useState<FundraiserGoalValidationErrors>({});
	const goal = useFormInput(`${fundraiser.goal}`);

	function updateDialogState(): void {
		toggleDialogState(!dialogState);
	}

	function handleSelectedCharity(charity: Charity): void {
		setCharity(charity);
		updateDialogState();
	}

	function validateAndSaveFundraiser(): void {
		const fundraiser: FundrasierDTO = {
			goal: parseInt(goal.value, 10),
			charityName: charity.name,
			charityEin: charity.ein
		};

		const errors: FundraiserGoalValidationErrors = validateFundraiser(fundraiser);
		if (Object.keys(errors).length === 0) {
			saveLocalFundraiser(fundraiser);
			handleNext();
		} else {
			setErrorValue(errors);
		}
	}

	return (
		<div className={classes.section}>
			<TextField
				style={{ margin: '40px 0' }}
				{...goal}
				label="Enter your fundraising goal"
				fullWidth={true}
				placeholder={'1,000'}
				InputLabelProps={{
					classes: {
						root: classes.rootLabel
					}
				}}
				InputProps={{
					inputComponent: NumberFormatCustom,
					classes: {
						root: classes.rootGoalInput,
						input: classes.inputType
					},
					startAdornment: (
						<InputAdornment position="start">
							<strong>$</strong>
						</InputAdornment>
					)
				}}
				error={isSet(errors.goal)}
				helperText={errors.goal}
			/>
			<List>
				<ListItem
					button
					divider
					aria-haspopup="true"
					aria-controls="non-profit-menu"
					aria-label="Non Profit"
					onClick={updateDialogState}
				>
					<ListItemText
						primary="Your nonprofit or organization"
						secondary={charity.name}
						classes={{
							primary: errors.charity && charity.ein === '' ? classes.listErrorStyling : null,
							secondary: errors.charity && charity.ein === '' ? classes.listErrorStyling : null
						}}
					/>
				</ListItem>
			</List>
			<NonProfitDialog open={dialogState} onClose={updateDialogState} onSelectedCharity={handleSelectedCharity} />
			<WizardButtonGroup showBackButton={false} advanceButtonLabel={'Next'} handleNext={validateAndSaveFundraiser} />
		</div>
	);
};

const mapStateToProps = (state: AppState): any => {
	return {
		fundraiser: fundraiserMembers.selectors.selectFundraiserData(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			saveLocalFundraiser: fundraiserMembers.actions.setFundraiserValues
		},
		dispatch
	);
};

const customStyles = (theme: Theme): any => ({
	rootLabel: {
		fontSize: '25px',
		fontWeight: 300,
		color: '#000000',
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	rootGoalInput: {
		paddingTop: '10px',
		fontSize: '54px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '30px'
		}
	},
	rootTimeInput: {
		paddingTop: '10px',
		fontSize: '30px',
		fontWeight: 300,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	inputType: {
		textAlign: 'right'
	},
	section: {
		padding: '35px 100px',
		backgroundColor: '#F5FFF9'
	},
	textField: {
		textAlign: 'left',
		...textField,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	rootNormalInput: {
		...rootNormalInput,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	listErrorStyling: {
		color: 'red'
	}
});

export default flow([
	withStyles(customStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Goals);
