import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { ActiveDonation } from './model';

export const activeDonationRequest = (): Action => ({
	type: t.ACTIVE_STATS_REQUEST
});

export const activeDonationSuccess = (activeDonation: ActiveDonation): Action => ({
	type: t.ACTIVE_STATS_SUCCESS,
	data: activeDonation
});

export const activeDonationFailure = (error: Error): Action => ({
	type: t.ACTIVE_STATS_FAILURE,
	error
});

export const clearActiveDonation = (): Action => ({
	type: t.CLEAR_ACTIVE_STATS
});
