import { Avatar } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

class Group extends PureComponent {
	static propTypes = {
		classes: PropTypes.shape({
			headerContainer: PropTypes.string
		}).isRequired,
		children: PropTypes.array,
		bgColor: PropTypes.string,
		bgAvatarColor: PropTypes.string,
		gutterTop: PropTypes.bool,
		stepNumber: PropTypes.string,
		title: PropTypes.string,
		location: PropTypes.string
	};

	render() {
		const { children, classes, bgColor, gutterTop, title, location, stepNumber, bgAvatarColor } = this.props;

		const containerClasses = [classes.headerContainer];
		if (gutterTop) {
			containerClasses.push(classes.headerGutterTop);
		}

		return (
			<Fragment>
				<div className={containerClasses.join(' ')}>
					<div className={classes.headerItem}>
						<Avatar style={{ backgroundColor: bgAvatarColor }}>{stepNumber}</Avatar>
					</div>
					<Typography className={classes.headerItem} variant={'h6'}>
						{title}
					</Typography>
					<Link component={RouterLink} to={location}>
						edit
					</Link>
				</div>
				<div className={classes.itemContainer} style={{ backgroundColor: bgColor }}>
					{children}
				</div>
			</Fragment>
		);
	}
}

const styles = {
	headerContainer: {
		width: '100%',
		padding: '5px 10px',
		marginBottom: '15px'
	},
	headerItem: {
		display: 'inline-block',
		paddingRight: 15
	},
	headerGutterTop: {
		marginTop: '10px'
	},
	itemContainer: {
		borderLeft: '1px solid #BCBCBC',
		marginLeft: '31px',
		padding: '24px'
	}
};

export default withStyles(styles)(Group);
