import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { DonationSuccessDTO } from './model';

export const publicDonationRequest = (): Action => ({
	type: t.PUBLIC_DONATION_REQUEST
});

export const publicDonationSuccess = (successfulDonation: DonationSuccessDTO): Action => ({
	type: t.PUBLIC_DONATION_SUCCESS,
	data: successfulDonation
});

export const publicDonationFailure = (error: Error): Action => ({
	type: t.PUBLIC_DONATION_FAILURE,
	error
});
export const clearPubicDonation = (): Action => ({
	type: t.PUBLIC_DONATION_CLEAR
});

export const setDonationAmount = (amount: string): Action => ({
	type: t.PUBLIC_DONATION_SET_AMOUNT,
	data: { amount }
});

export const setDonationTip = (tip: string): Action => ({
	type: t.PUBLIC_DONATION_SET_TIP,
	data: { tip }
});
