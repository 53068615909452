const DEFAULT_PRIMARY_COLOR = '#FFF3C4';
const DEFAULT_SECONDARY_COLOR = '#107900';

export const getPrimaryColor = (primaryColor?: string): string => {
	return primaryColor ? primaryColor : DEFAULT_PRIMARY_COLOR;
};

export const getSecondaryColor = (secondaryColor?: string): string => {
	return secondaryColor ? secondaryColor : DEFAULT_SECONDARY_COLOR;
};
