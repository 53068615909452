import { Theme } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import withStyles from '@material-ui/core/styles/withStyles';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement, useState } from 'react';

import Section from '../../../component/containers/section';
import Header from '../../../component/header';
import Branding from './steps/branding';
import Goals from './steps/goals';
import Settings from './steps/settings';

interface Props {
	activeStep?: number;
	classes: any;
	stepId: number;
}

const SignUp = (props: Props): ReactElement => {
	const { classes, stepId } = props;
	const [activeStep, setStep] = useState<number>(stepId);

	const handleNext = (): void => {
		setStep(activeStep + 1);
	};

	const handleBack = (): void => {
		setStep(activeStep - 1);
	};

	return (
		<Fragment>
			<Header disableButtons={true} />
			<Section>
				<div className={classes.stepperContainer}>
					<Stepper activeStep={activeStep} orientation="vertical" style={{ flex: 1 }}>
						<Step key={1}>
							<StepLabel
								StepIconProps={{
									classes: {
										root: classes.stepIconRoot,
										active: classes.goalColor,
										completed: classes.goalColor
									}
								}}
							>
								<div className={classes.stepLabelContainer}>Fundraiser Goal & Nonprofit Name</div>
							</StepLabel>
							<StepContent className={classes.stepContent}>
								<Goals handleNext={handleNext} handleBack={handleBack} />
							</StepContent>
						</Step>
						<Step key={2}>
							<StepLabel
								StepIconProps={{
									classes: {
										root: classes.stepIconRoot,
										active: classes.brandingColor,
										completed: classes.brandingColor
									}
								}}
							>
								<div className={classes.stepLabelContainer}>Category, title & description</div>
							</StepLabel>
							<StepContent className={classes.stepContent}>
								<Branding handleNext={handleNext} handleBack={handleBack} />
							</StepContent>
						</Step>
						<Step key={3}>
							<StepLabel
								StepIconProps={{
									classes: {
										root: classes.stepIconRoot,
										active: classes.settingsColor,
										completed: classes.settingsColor
									}
								}}
							>
								<div className={classes.stepLabelContainer}>Settings</div>
							</StepLabel>
							<StepContent className={classes.stepContent}>
								<Settings activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
							</StepContent>
						</Step>
					</Stepper>
				</div>
			</Section>
		</Fragment>
	);
};

SignUp.defaultProps = {
	stepId: 0
};

const customStyles = (theme: Theme): any => ({
	stepperContainer: {
		width: '60%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	button: {
		marginTop: theme.spacing.unit,
		marginRight: theme.spacing.unit
	},
	actionsContainer: {
		marginBottom: theme.spacing.unit * 2
	},
	resetContainer: {
		padding: theme.spacing.unit * 3
	},
	stepContent: {
		paddingLeft: 0
	},
	stepIconRoot: {
		fontSize: '45px',
		marginLeft: '-12px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '30px',
			marginLeft: '-3px'
		}
	},
	goalColor: {
		color: '#77C49E !important'
	},
	brandingColor: {
		color: '#E7C313 !important'
	},
	settingsColor: {
		color: '#E46003 !important'
	},
	stepLabelContainer: {
		fontSize: '30px',
		fontWeight: '300',
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	}
});

export default flow([withStyles(customStyles)])(SignUp);
