import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatDecimalCustom = props => {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
			thousandSeparator
			decimalScale={2}
		/>
	);
};

NumberFormatDecimalCustom.propTypes = {
	inputRef: PropTypes.func,
	onChange: PropTypes.func
};

export default NumberFormatDecimalCustom;
