import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import NumberFormatDecimalCustom from '../../../../../component/inputNumberFormatDecimal';
import { AppState } from '../../../../../store';
import publicDonationMembers from '../../../../../store/modules/publicDonation';
import {
	DEFAULT_CUSTOM_PERCENT,
	calculateValueForPercent,
	calculatedPrice,
	calculationDonationAmount
} from '../utilities/tipCalculation';

interface Props {
	classes: any;
	formDonationAmount: string;
	setTip(tip: any): void;
}

const TipCalculation = (props: Props): ReactElement => {
	const { classes, formDonationAmount } = props;
	const [state, setState] = useState({
		tipPercent: DEFAULT_CUSTOM_PERCENT,
		customTipAmount: ''
	});

	useEffect((): void => {
		// We want to handle the case where we are changing the donation amount on the parent container
		// then communicating back up to the parent container the new tip value.

		// With this, we are adjusting the tip each time we modify state
		// - this captures things like changing the select value AND changing the customTipAmount
		props.setTip(calculationDonationAmount(formDonationAmount, state.tipPercent, state.customTipAmount).toFixed(2));
	}, [formDonationAmount, state]);

	function handleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
		setState({
			...state,
			[event.target.name]: event.target.value
		});
	}

	function handleCustomTipAmount(event: React.ChangeEvent<HTMLInputElement>): void {
		setState({
			...state,
			customTipAmount: event.target.value
		});
	}

	return (
		<Fragment>
			<Typography variant="body1" className={classes.tipContainer}>
				4giving has a no platform fee for organizations and counts on your geneorsity to operate our service.
			</Typography>
			<Typography variant="body1" className={classes.tipContainer} style={{ marginBottom: 0 }}>
				Thank you for keeping 4giving free for all organizations with a tip of:
			</Typography>
			<div>
				<FormControl className={classes.formControl}>
					<InputLabel htmlFor="tip-percentage" />
					<Select value={state.tipPercent} onChange={handleChange} name="tipPercent" id="tip-percentage">
						<MenuItem value={0.05}>
							{formDonationAmount ? `5% (${calculateValueForPercent('5', formDonationAmount)})` : '5%'}
						</MenuItem>
						<MenuItem value={0.1}>
							{formDonationAmount ? `10% (${calculateValueForPercent('10', formDonationAmount)})` : '10%'}
						</MenuItem>
						<MenuItem value={0.15}>
							{formDonationAmount ? `15% (${calculateValueForPercent('15', formDonationAmount)})` : '15%'}
						</MenuItem>
						<MenuItem value={0}>Other</MenuItem>
					</Select>
				</FormControl>
			</div>
			{state.tipPercent === 0 && (
				<div>
					<FormControl className={classes.formControl}>
						<TextField
							onChange={handleCustomTipAmount}
							label=""
							value={
								!state.customTipAmount && formDonationAmount
									? (parseInt(formDonationAmount, 10) * DEFAULT_CUSTOM_PERCENT).toFixed(2)
									: state.customTipAmount
							}
							placeholder={'1,000'}
							type="tel"
							InputProps={{
								inputComponent: NumberFormatDecimalCustom,
								startAdornment: (
									<InputAdornment position="start">
										<strong>$</strong>
									</InputAdornment>
								)
							}}
						/>
					</FormControl>
				</div>
			)}
			<Typography variant="body1" className={classes.tipContainer}>
				Total Charge: {calculatedPrice(formDonationAmount, state.tipPercent, state.customTipAmount)}
			</Typography>
		</Fragment>
	);
};

const customStyles = (): any => ({
	tipContainer: {
		marginBottom: '15px'
	},
	formControl: {
		minWidth: 130,
		marginLeft: 'auto',
		marginRight: 'auto',

		textAlign: 'left',
		marginBottom: '15px'
	}
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			setTip: publicDonationMembers.actions.setDonationTip
		},
		dispatch
	);
};

export default flow([
	withStyles(customStyles),
	connect(
		null,
		mapDispatchToProps
	)
])(TipCalculation);
