import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../..';
import { METHODS } from '../service/model';
import { selectRequestor } from '../service/selectors';
import { donationFailure, donationRequest, donationSuccess } from './actions';
import { DonationsReturned } from './model';
import { selectDonationInFlight } from './selectors';

export const getDonations = (): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectDonationInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);

		dispatch(donationRequest());
		try {
			const response: AxiosResponse<DonationsReturned> = await requestor.executeApi({
				endpoint: '/donation',
				method: METHODS.GET
			});
			dispatch(donationSuccess(response.data.donations));
		} catch (e) {
			dispatch(donationFailure(e));
		}
	};
};
