import { Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { Fragment, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../../../store';
import fundrasierMembers from '../../../store/modules/fundraiser';
import { Fundraiser } from '../../../store/modules/fundraiser/model';
import FundraiserDonors from './donors';
import FundraiserStats from './stats';

interface Props {
	classes: any;
	fundraisers: Fundraiser[];
	getOrganizationFundriasers(publicId: string): void;
	match: {
		params: {
			id: string;
		};
	};
}

interface State {
	[key: string]: number;
}

class OrganizationStats extends PureComponent<Props> {
	public state: State = {};

	public componentWillMount(): void {
		this.props.getOrganizationFundriasers(this.props.match.params.id);
	}

	private handleChange = (stateKey: string) => {
		return (event: any, selectedValue: any) => {
			this.setState({ [stateKey]: selectedValue });
		};
	};

	public render(): ReactElement {
		const { classes, fundraisers } = this.props;

		if (!fundraisers) {
			return <div>Loading</div>;
		}

		return (
			<Fragment>
				<Typography variant="h5" gutterBottom>
					Fundraisers
				</Typography>

				{fundraisers.map(fundraiser => {
					if (!fundraiser.publicAccessCode) {
						return null;
					}

					return (
						<div style={{ marginTop: '25px' }} key={fundraiser.publicId}>
							<div className={classes.toolbarContainer}>
								<div className={classes.toolbarTitle}>
									<Typography variant="h6" gutterBottom>
										{fundraiser.title}
									</Typography>
								</div>
								<div className={classes.spacer} />
								<div className={classes.toolbarAction}>Edit</div>
								<div className={classes.toolbarAction}>
									<a
										href={`/donation/${fundraiser.publicAccessCode}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										View
									</a>
								</div>
								<div className={classes.toolbarAction}>Pause</div>
							</div>
							<div className={classes.viewSelection}>
								<Tabs
									value={
										this.state[fundraiser.publicAccessCode] === undefined
											? false
											: this.state[fundraiser.publicAccessCode]
									}
									onChange={this.handleChange(fundraiser.publicAccessCode)}
									textColor={'secondary'}
									classes={{
										root: classes.tabContainer,
										indicator: classes.tabIndicator
									}}
								>
									<Tab
										classes={{
											selected: classes.tabSelected
										}}
										label="Stats"
									/>
									<Tab
										classes={{
											selected: classes.tabSelected
										}}
										label="Donors"
									/>
								</Tabs>
							</div>
							<FundraiserStats
								visible={this.state[fundraiser.publicAccessCode] === 0}
								publicId={fundraiser.publicId}
								metrics={fundraiser.metrics}
							/>
							<FundraiserDonors
								visible={this.state[fundraiser.publicAccessCode] === 1}
								publicId={fundraiser.publicId}
							/>
						</div>
					);
				})}
			</Fragment>
		);
	}
}

const styles = (): any => ({
	toolbarTitle: {
		flex: '0 0 auto'
	},
	toolbarDate: {
		marginLeft: '15px',
		align: 'bottom'
	},
	toolbarAction: {
		textAlign: 'center',
		margin: '0px 15px'
	},

	spacer: {
		flex: '1 1 100%'
	},
	toolbarContainer: {
		display: 'flex'
	},
	tabContainer: {
		backgroundColor: 'rgba(103,186,140,.2)'
	},
	tabIndicator: {
		backgroundColor: 'rgba(103,186,140,.4)'
	},
	tabSelected: {
		backgroundColor: 'rgba(103,186,140,.4)',
		color: 'black !important'
	}
});

const mapStateToProps = (state: AppState, ownProps: Props): any => {
	return {
		fundraisers: fundrasierMembers.selectors.selectFundraisers(state, ownProps.match.params.id)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			getOrganizationFundriasers: fundrasierMembers.thunks.getFundraisers
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withRouter
])(OrganizationStats);
