import {
	Button,
	FormControl,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Theme,
	Toolbar,
	Typography,
	withStyles
} from '@material-ui/core';
import { History } from 'history';
import find from 'lodash/find';
import flow from 'lodash/flow';
import React, { Fragment, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import privateRoutes from '../../../core/routes/private';
import publicRoutes from '../../../core/routes/public';
import { AppState } from '../../../store';
import organizationMembers from '../../../store/modules/organization';
import { Organization } from '../../../store/modules/organization/model';
import OrgTable from './orgTable';

interface Props {
	classes: any;
	history: History;
	requestInFlight: boolean;
	organizationList: Organization[];
	getOrganizations(): void;
}

class Dashboard extends PureComponent<Props> {
	public state = {
		selectedOrganization: ''
	};

	public componentWillMount(): void {
		this.props.getOrganizations();
	}

	public componentWillReceiveProps(incomingProps: Props): void {
		if (this.state.selectedOrganization === '' && incomingProps.organizationList.length > 0) {
			this.setState({ selectedOrganization: incomingProps.organizationList[0].publicId });
		}
	}

	private handleNavigation = (): void => {
		this.props.history.push(privateRoutes.organizationStats.getRoute(this.state.selectedOrganization));
	};

	private handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
		this.setState({
			selectedOrganization: e.target.value
		});
	};

	public render(): ReactElement {
		const { classes, organizationList } = this.props;

		if (this.props.requestInFlight || !organizationList) {
			return <div>Loading</div>;
		}

		const organization = find(organizationList, { publicId: this.state.selectedOrganization });

		if (!organizationList || organizationList.length === 0) {
			return (
				<Fragment>
					<Typography variant="body2" gutterBottom>
						Your fundraisering organizations will appear here once you start a fundraiser. Create one today and get
						started!
					</Typography>
					<Link href={publicRoutes.onboarding.route}>Create a Fundraiser</Link>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<Toolbar className={classes.toolbarContainer}>
					<div className={classes.toolbarTitle}>
						<FormControl className={classes.formControl}>
							<InputLabel>Selected Organization</InputLabel>
							<Select value={this.state.selectedOrganization} onChange={this.handleSelectChange}>
								{organizationList.map(org => {
									return (
										<MenuItem key={org.publicId} value={org.publicId}>
											{org.name}
										</MenuItem>
									);
								})}
								}
							</Select>
						</FormControl>
					</div>
					<div className={classes.spacer} />
					<div className={classes.toolbarTitle}>
						<Button onClick={this.handleNavigation} variant="contained" color="primary" className={classes.button}>
							View fundraisers
						</Button>
					</div>
				</Toolbar>
				{organization && <OrgTable organizationPublicId={organization.publicId} />}
			</Fragment>
		);
	}
}

const styles = (theme: Theme): any => ({
	toolbarTitle: {
		flex: '0 0 auto'
	},
	button: {
		textTransform: 'none',
		margin: theme.spacing.unit
	},
	spacer: {
		flex: '1 1 100%'
	},
	formControl: {
		minWidth: 200
	}
});

const mapStateToProps = (state: AppState): any => {
	return {
		requestInFlight: organizationMembers.selectors.selectOrganizationRequestInFlight(state),
		organizationList: organizationMembers.selectors.selectOrganizationList(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			getOrganizations: organizationMembers.thunks.getOrganizations
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withRouter
])(Dashboard);
