import { Grid, Table, TableBody, TableCell, TableRow, Theme, Typography, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import DonationBar from '../../../component/donationBar';
import FormatNumber from '../../../component/numberFormat';
import { AppState } from '../../../store';
import { TypeAdjustment } from '../../../store/common/metrics';
import activeDonationMembers from '../../../store/modules/activeDonation';
import { ActiveDonation } from '../../../store/modules/activeDonation/model';
import publicFundraiserMembers from '../../../store/modules/publicFundraiser';
import { PublicFundraiser } from '../../../store/modules/publicFundraiser/model';
import { getPrimaryColor, getSecondaryColor } from '../../../utility/theme';

interface Props {
	classes: any;
	stats?: ActiveDonation;
	fundraiserId: string;
	getPublicFundraiser(publicId: string): void;
	fetchStats(publicId: string): void;
	fundraiser: PublicFundraiser;
	hasError: boolean;
	clearPublicFundraiser(): void;
}

class Active extends PureComponent<Props> {
	private fetchId: any = undefined;

	public componentDidMount(): void {
		this.props.getPublicFundraiser(this.props.fundraiserId);
	}

	public componentWillReceiveProps(incomingProps: Props): void {
		if (!this.props.fundraiser.publicAccessCode && incomingProps.fundraiser.publicAccessCode) {
			this.props.fetchStats(this.props.fundraiserId);
			if (!this.fetchId) {
				this.fetchId = setInterval(() => {
					this.props.fetchStats(this.props.fundraiserId);
				}, 2000);
			}
		}
	}

	public componentWillUnmount(): void {
		clearInterval(this.fetchId);
		this.props.clearPublicFundraiser();
	}

	public render(): ReactElement {
		const { classes, stats, fundraiser, hasError } = this.props;
		/* Renderings */
		if (hasError) {
			// @todo: Need design for error page
			return <div>Sorry! That is an invalid fundraiser id</div>;
		}
		if (!fundraiser.publicAccessCode) {
			// @todo: Need design for loading
			return <div>loading</div>;
		}

		if (!stats || !stats.donations) {
			return <div>Sorry! There was an error loading that fundraisers stats. Please try again</div>;
		}

		return (
			<div className={classes.container}>
				<DonationBar
					goal={stats.goal}
					numberDonors={stats.donors.allTime}
					primaryColor={getPrimaryColor(fundraiser.sitePrimaryColor)}
					secondaryColor={getSecondaryColor(fundraiser.siteSecondaryColor)}
					totalDonated={stats.donations.allTime}
				/>
				<div className={classes.marketingBar}>
					<Grid container>
						<Grid item xs={12} md={4}>
							<Typography variant="h4" className={classes.leaderboardTitle}>
								Latest Donations
							</Typography>
							<Table className={classes.tableContainer}>
								<TableBody>
									{stats.latestDonors.map((activeDonor, i) => (
										<TableRow className={classes.tableRow} key={i}>
											<TableCell
												classes={{
													body: classes.tableCellBody
												}}
											>
												{activeDonor.anonymous ? 'Anonymous Giver' : activeDonor.name}
											</TableCell>
											<TableCell
												classes={{
													body: classes.tableCellBody
												}}
											>
												{FormatNumber(activeDonor.donatedAmount, TypeAdjustment.price)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
						<Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
							<img src={fundraiser.smallImage} style={{ maxWidth: '320px' }} />
							test
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography variant="h4" className={classes.leaderboardTitle}>
								Leaderboard
							</Typography>
							<Table className={classes.tableContainer}>
								<TableBody>
									{stats.leaderboard.map((activeDonor, i) => (
										<TableRow className={classes.tableRow} key={i}>
											<TableCell
												classes={{
													body: classes.tableCellBody
												}}
											>
												{activeDonor.anonymous ? 'Anonymous Giver' : activeDonor.name}
											</TableCell>
											<TableCell
												classes={{
													body: classes.tableCellBody
												}}
											>
												{FormatNumber(activeDonor.donatedAmount, TypeAdjustment.price)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				</div>
				<div className="content">
					<div className="logo" />
				</div>
			</div>
		);
	}
}

const styles = (theme: Theme): any => {
	return {
		container: {
			marginLeft: 'auto',
			marginRight: 'auto',
			maxWidth: '1440px',
			width: '100vw'
		},
		leaderboardTitle: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.5rem',
				textAlign: 'center',
				marginTop: '25px'
			}
		},
		marketingBar: {
			margin: '2vw'
		},
		tableRow: {
			borderBottom: 'none',
			'&:nth-of-type(odd)': {
				backgroundColor: 'rgba(253,200,88,.1)'
			}
		},
		tableCellBody: {
			fontSize: 22,
			borderBottom: 'none'
		},
		tableContainer: {
			marginTop: 15,
			marginBottom: 15
		}
	};
};

const mapStateToProps = (state: AppState): any => {
	return {
		fundraiser: publicFundraiserMembers.selectors.selectPublicFundraiserEntry(state),
		hasError: publicFundraiserMembers.selectors.selectPublicFundraiserRequestFailed(state),
		stats: activeDonationMembers.selectors.selectActiveStats(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			fetchStats: activeDonationMembers.thunks.getPublicFundraiser,
			clearPublicFundraiser: publicFundraiserMembers.actions.clearPublicFundraiser,
			getPublicFundraiser: publicFundraiserMembers.thunks.getPublicFundraiser
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Active);
