import Auth0Lock from 'auth0-lock';
import jwtDecode from 'jwt-decode';

import { findEnvAttr } from './process';

interface DecodedToken {
	readonly at_hash: string;
	readonly aud: string;
	readonly email: string;
	readonly email_verified: boolean;
	readonly exp: number;
	readonly iat: number;
	readonly iss: string;
	readonly nonce: string;
	readonly sub: string;
}

interface Profile {
	readonly email: string;
	readonly email_Verified: boolean;
	readonly sub: string;
}

export const lock = new Auth0Lock(findEnvAttr('REACT_APP_AUTH_0_AUDIENCE_ID'), 'auth.4givingapp.net', {
	configurationBaseUrl: 'https://cdn.auth0.com',
	autoclose: true,
	auth: {
		redirectUrl: findEnvAttr('REACT_APP_AUTH_0_REDIRECT_URI'),
		redirect: false,
		responseType: 'token id_token',
		params: {
			scope: 'openid email' // Learn about scopes: https://auth0.com/docs/scopes
		}
	},
	theme: {
		logo: 'https://s3.amazonaws.com/assets.4giving.com/logo-transparent.png',
		primaryColor: '#2096F3'
	},
	languageDictionary: {
		title: ' ',
		signUpTerms: `By signing up you agree to our <a href="${findEnvAttr('REACT_APP_BASE_URL')}/terms" target="_blank"
										rel="noopener noreferrer">Terms and Conditions</a>`
	},
	additionalSignUpFields: [
		{
			name: 'full_name',
			placeholder: 'Enter your full name'
		}
	],
	mustAcceptTerms: true
});

export function getToken(): string | null {
	// Retrieves the user token from window.localStorage
	return window.localStorage.getItem('id_token');
}

export function getTokenExpirationDate(): Date | null {
	const token = getToken();
	if (!token) {
		return null;
	}
	const decoded: DecodedToken = jwtDecode(token);
	if (decoded && !decoded.exp) {
		return null;
	}

	const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
	date.setUTCSeconds(decoded.exp);
	return date;
}

export function isTokenExpired(): boolean {
	const token = getToken();
	if (!token) {
		return true;
	}

	const date = getTokenExpirationDate();
	const offsetSeconds = 0;
	if (date === null) {
		return false;
	}
	return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export function login(options?: Auth0LockShowOptions): void {
	// Call the show method to display the widget.
	lock.show(options);
}

export function loggedIn(): boolean {
	// Checks if there is a saved token and it's still valid
	const token = getToken();
	return !!token && !isTokenExpired();
}

export function logout(): void {
	// Clear user token and profile data from window.localStorage
	window.localStorage.removeItem('id_token');
	window.localStorage.removeItem('profile');
}

export function getProfile(): Profile {
	// Retrieves the profile data from window.localStorage
	const profile = window.localStorage.getItem('profile');
	return profile ? JSON.parse(window.localStorage.profile) : {};
}

export function setToken(idToken: string): void {
	// Saves user token to window.localStorage
	window.localStorage.setItem('id_token', idToken);
}
