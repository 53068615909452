import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import authMembers from '../store/modules/auth';
import AppContainer from './AppContainer.tsx';
import PrivateContainer from './containers/privateContainer';
import history from './history';
import PrivateRoute from './privateRoute';
import routes from './routes';
import privateRoues from './routes/private';

class PrivateRouteContainer extends PureComponent {
	render() {
		return (
			<PrivateContainer>
				{Object.values(privateRoues).map(route => {
					return (
						<PrivateRoute
							exact
							key={route.route}
							path={route.route}
							component={route.getComponent}
							isAuthenticated={this.props.isAuthenticated}
						/>
					);
				})}
			</PrivateContainer>
		);
	}
}

PrivateRouteContainer.propTypes = {
	isAuthenticated: PropTypes.bool
};

const router = props => {
	return (
		<Router history={history}>
			<AppContainer>
				<Switch>
					{Object.values(routes).map(route => {
						return <Route exact key={route.route} path={route.route} render={route.getComponent} />;
					})}
					<Route
						exact
						path={['/dashboard/*', '/dashboard']}
						component={() => <PrivateRouteContainer isAuthenticated={props.isAuthenticated} />}
					/>
					<Redirect from="*" to="/" />
				</Switch>
			</AppContainer>
		</Router>
	);
};

router.propTypes = {
	isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		isAuthenticated: authMembers.selectors.selectIsAuthenticated(state)
	};
};

export default connect(mapStateToProps)(router);
