import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

import { TypeAdjustment } from '../../store/common/metrics';

const FormatNumber = (number: number, type: TypeAdjustment, decimalValue: boolean = false): ReactElement => {
	if (type === TypeAdjustment.price) {
		const value = decimalValue ? (number / 100).toFixed(2) : number / 100;

		return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />;
	}
	return <NumberFormat value={number} displayType={'text'} thousandSeparator={true} />;
};

export default FormatNumber;
