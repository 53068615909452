import { Button, DialogActions, Grid, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import flow from 'lodash/flow';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../../../../../store';
import charityMembers from '../../../../../store/modules/charity';
import { Charity } from '../../../../../store/modules/charity/model';
import { useFormInput } from '../../../../../utility/formHelpers';

interface Props {
	classes: any;
	charities: [Charity];
	searchForCharities(searchTerm: string): void;
	onSelectedCharity(charity: Charity): void;
	onClose(): void;
	open: boolean;
}

const NonProfitDialog = (props: Props): ReactElement => {
	const nonProfitSearch = useFormInput('');

	useEffect(() => {}, [props.charities]);

	const handleSearch = (e: any): void => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		props.searchForCharities(nonProfitSearch.value);
	};

	return (
		<Dialog disableBackdropClick maxWidth="lg" open={props.open} onClose={props.onClose}>
			<DialogTitle id="confirmation-dialog-title">Your nonprofit or organization</DialogTitle>
			<DialogContent style={{ width: '600px' }}>
				<form onSubmit={handleSearch}>
					<div className={props.classes.searchContainer}>
						<TextField fullWidth={true} label="Search for a nonprofit by name or EIN" {...nonProfitSearch} />
						<div className={props.classes.searchButtonContainer}>
							<Button color="primary" variant="contained" onClick={handleSearch}>
								Search
							</Button>
						</div>
					</div>
				</form>

				<Grid container>
					{props.charities.map((charity: Charity, key: number) => {
						return (
							<Grid item xs={12} className={props.classes.charityContainer} key={key}>
								<Grid container>
									<Grid item xs={8}>
										<Typography variant="h6">{charity.name}</Typography>
										<Typography variant="body2" color="textPrimary">
											{charity.city}, {charity.state}
										</Typography>
										<Typography variant="caption" color="textPrimary">
											EIN: {charity.ein}
										</Typography>
									</Grid>
									<Grid item xs={4} style={{ textAlign: 'right', marginTop: '15px' }}>
										<Button
											variant="contained"
											color="primary"
											onClick={() => {
												props.onSelectedCharity(charity);
											}}
										>
											Select
										</Button>
									</Grid>
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const customStyles = (): any => ({
	charityContainer: {
		backgroundColor: 'rgba(103, 186, 140, .06)',
		marginBottom: '20px',
		borderLeft: '1px solid #77C49E',
		padding: '23px 50px'
	},
	searchContainer: {
		display: 'inline-flex',
		width: '100%',
		marginBottom: '25px'
	},
	searchButtonContainer: {
		marginLeft: '15px',
		marginTop: '12px'
	}
});

const mapStateToProps = (state: AppState): any => {
	return {
		charities: charityMembers.selectors.selectCharitys(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			searchForCharities: charityMembers.thunks.searchForCharities
		},
		dispatch
	);
};

export default flow([
	withStyles(customStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(NonProfitDialog);
