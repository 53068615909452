import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { PublicFundraiser } from './model';

export const publicFundraiserRequest = (): Action => ({
	type: t.PUBLIC_FUNDRAISER_REQUEST
});

export const publicFundraiserSuccess = (publicFundraiser: PublicFundraiser): Action => ({
	type: t.PUBLIC_FUNDRAISER_SUCCESS,
	data: publicFundraiser
});

export const publicFundraiserFailure = (error: Error): Action => ({
	type: t.PUBLIC_FUNDRAISER_FAILURE,
	error
});

export const clearPublicFundraiser = (): Action => ({
	type: t.CLEAR_PUBLIC_FUNDRAISER
});
