import { Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import flow from 'lodash/flow';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import WizardButtonGroup from '../../../../../component/wizardButtonGroup/wizardButtonGroup';
import { AppState } from '../../../../../store';
import fundraiserMembers from '../../../../../store/modules/fundraiser';
import { FundrasierDTO } from '../../../../../store/modules/fundraiser/model';
import { isSet, useFormInput } from '../../../../../utility/formHelpers';
import { rootLabel, rootNormalInput, section, textField } from '../../styleDefinitions';
import { FundraiserBrandingValidationErrors, validateBranding } from './validations';

interface Props {
	classes: any;
	fundraiser: FundrasierDTO;
	handleBack(): void;
	handleNext(): void;
	saveLocalFundraiser(fundraiser: FundrasierDTO): void;
}

const Branding = (props: Props): ReactElement => {
	const { classes, handleBack, handleNext, fundraiser, saveLocalFundraiser } = props;
	const [errors, setErrorValue] = useState<FundraiserBrandingValidationErrors>({});
	const title = useFormInput(fundraiser.title || '');
	const description = useFormInput(fundraiser.description || '');

	function validateAndSaveFundraiser(): void {
		const validateFundraiser: FundrasierDTO = {
			title: title.value,
			description: description.value,
			banner: 'https://s3.amazonaws.com/assets.4giving.com/defaults/fundraiserBanners/FundraiserBannerDefault.png',
			logoInitials: title.value.substr(0, 2)
		};
		const errors: FundraiserBrandingValidationErrors = validateBranding(validateFundraiser);
		if (Object.keys(errors).length === 0) {
			saveLocalFundraiser(validateFundraiser);
			handleNext();
		} else {
			setErrorValue(errors);
		}
	}

	return (
		<div className={classes.section}>
			<TextField
				fullWidth={true}
				className={classes.textField}
				label="Fundraiser Title"
				{...title}
				error={isSet(errors.title)}
				helperText={errors.title}
				InputProps={{
					classes: {
						root: classes.rootNormalInput,
						input: classes.inputType
					}
				}}
				InputLabelProps={{
					classes: {
						root: classes.rootLabel
					}
				}}
			/>
			<TextField
				fullWidth={true}
				multiline={true}
				className={classes.textField}
				label="Fundraiser Description"
				{...description}
				error={isSet(errors.description)}
				helperText={errors.description}
				InputProps={{
					classes: {
						root: classes.rootNormalInput,
						input: classes.inputType
					}
				}}
				InputLabelProps={{
					classes: {
						root: classes.rootLabel
					}
				}}
			/>
			<WizardButtonGroup advanceButtonLabel={'Next'} handleBack={handleBack} handleNext={validateAndSaveFundraiser} />
		</div>
	);
};

const customStyles = (theme: Theme): any => ({
	textField: {
		...textField,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	rootLabel: {
		...rootLabel,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	rootNormalInput: {
		...rootNormalInput,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	section: {
		...section,
		backgroundColor: '#FEFBF1'
	}
});

const mapStateToProps = (state: AppState): any => {
	return {
		fundraiser: fundraiserMembers.selectors.selectFundraiserData(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			saveLocalFundraiser: fundraiserMembers.actions.setFundraiserValues
		},
		dispatch
	);
};

export default flow([
	withStyles(customStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Branding);
