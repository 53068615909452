import { Theme, Typography, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import DonationBar from '../../../component/donationBar';
import { AppState } from '../../../store';
import activeDonationMembers from '../../../store/modules/activeDonation';
import { ActiveDonation } from '../../../store/modules/activeDonation/model';
import publicDonationMembers from '../../../store/modules/publicDonation';
import { DonationSuccessDTO, PublicDonationDTO } from '../../../store/modules/publicDonation/model';
import publicFundraiserMembers from '../../../store/modules/publicFundraiser';
import { PublicFundraiser } from '../../../store/modules/publicFundraiser/model';
import { getPrimaryColor, getSecondaryColor } from '../../../utility/theme';
import DonationLoading from './donationLoading';
import DonationSection from './donationSection';
import DonationSuccess from './donationSuccess';
import DonationHeader from './header';

interface Props {
	classes: any;
	clearPublicFundraiser(): void;
	donate(publicDonation: PublicDonationDTO): void;
	fetchStats(publicId: string): void;
	fundraiser: PublicFundraiser;
	fundraiserId: string;
	getPublicFundraiser(fundraiserKey: string): void;
	hasError: boolean;
	processingDonation: boolean;
	stats?: ActiveDonation;
	successfulDonation?: DonationSuccessDTO;
}

const DonationPage = (props: Props): ReactElement => {
	// State tracking variables
	const { classes, donate, fundraiserId, fundraiser, successfulDonation, processingDonation, stats } = props;

	// Lifecycle Events
	useEffect(() => {
		// get public donation
		props.getPublicFundraiser(fundraiserId);
		props.fetchStats(fundraiserId);

		return function cleanup(): void {
			props.clearPublicFundraiser();
		};
	}, []);

	useEffect(() => {
		if (successfulDonation) {
			props.fetchStats(fundraiserId);
		}
	}, [successfulDonation]);

	/* Renderings */
	if (!fundraiser || !fundraiser.goal) {
		// @todo: Need design for loading
		return <div>loading</div>;
	}
	if (props.hasError) {
		// @todo: Need design for error page
		return <div>Sorry! That was an invalid fundraiser id</div>;
	}

	const getComponent = (): ReactElement => {
		if (successfulDonation) {
			return <DonationSuccess donation={successfulDonation} />;
		} else if (processingDonation) {
			return <DonationLoading />;
		}
		return <DonationSection fundraiser={fundraiser} donate={donate} fundraiserId={fundraiserId} />;
	};

	return (
		<div style={{ width: '100vw' }}>
			<div className={classes.headSection}>
				<DonationHeader bannerUrl={fundraiser.banner} logoUrl={fundraiser.logo} logoInitials={fundraiser.logoInitials} />
				<div className={classes.headerContainer}>
					<Typography variant="h3" gutterBottom className={classes.headerText}>
						{fundraiser.title}
					</Typography>
					{fundraiser.auction && (
						<Typography variant="body1" className={classes.headerTextSubtext}>
							Silent auction
						</Typography>
					)}
					{stats && stats.donations && !fundraiser.auction && (
						<DonationBar
							goal={stats.goal}
							numberDonors={stats.donors.allTime}
							primaryColor={getPrimaryColor(fundraiser.sitePrimaryColor)}
							secondaryColor={getSecondaryColor(fundraiser.siteSecondaryColor)}
							totalDonated={stats.donations.allTime}
							useSmall={true}
						/>
					)}
				</div>
			</div>
			{getComponent()}
		</div>
	);
};

const styles = (theme: Theme): any => {
	return {
		headerText: {
			textAlign: 'center',
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem'
			}
		},
		headerTextSubtext: {
			textAlign: 'center'
		},
		headerContainer: {
			marginTop: '10px',
			margin: '10px 5%',
			padding: '0px 180px 1px',
			[theme.breakpoints.down('md')]: {
				padding: '62px 0 9px'
			}
		},
		headSection: {
			boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.50)',
			marginBottom: 45,
			[theme.breakpoints.down('md')]: {
				marginBottom: 25
			}
		}
	};
};
const mapStateToProps = (state: AppState): any => {
	return {
		fundraiser: publicFundraiserMembers.selectors.selectPublicFundraiserEntry(state),
		hasError: publicFundraiserMembers.selectors.selectPublicFundraiserRequestFailed(state),
		processingDonation: publicDonationMembers.selectors.selectPublicDonationRequestInFlight(state),
		successfulDonation: publicDonationMembers.selectors.selectPublicDonationSuccessfulDonationInformation(state),
		stats: activeDonationMembers.selectors.selectActiveStats(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			fetchStats: activeDonationMembers.thunks.getPublicFundraiser,
			clearPublicFundraiser: publicFundraiserMembers.actions.clearPublicFundraiser,
			getPublicFundraiser: publicFundraiserMembers.thunks.getPublicFundraiser,
			donate: publicDonationMembers.thunks.postDonation
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(DonationPage);
