import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import Section from '../../../../component/containers/section';

interface Props {
	classes: any;
}

const DonationLoading = (props: Props): ReactElement => {
	return (
		<Section>
			<div className={props.classes.container}>
				<CircularProgress />
				<Typography variant="h4" gutterBottom style={{ marginTop: '25px' }}>
					Please wait while we process your donation.
				</Typography>
			</div>
		</Section>
	);
};

const customStyles = (): any => ({
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '80%',
		textAlign: 'center',
		maxWidth: '600px'
	}
});

export default withStyles(customStyles)(DonationLoading);
