import { Button, Grid, Theme, withStyles } from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { History } from 'history';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import privateRoutes from '../../core/routes/private';
import publicRoutes from '../../core/routes/public';
import { ReactComponent as Logo } from '../../core/svgAssets/4givingFullLogo.svg';
import { AppState } from '../../store';
import authMembers from '../../store/modules/auth';
import Section from '../containers/section';

interface Props {
	classes: any;
	disableButtons?: boolean;
	history: History;
	isAuthenticated: boolean;
	login(options?: Auth0LockShowOptions): void;
	logout(): void;
	showLogIn?: boolean;
	showSignUp?: boolean;
}

const Header = (props: Props): ReactElement => {
	const { classes, history, isAuthenticated, login, logout, disableButtons, showSignUp, showLogIn } = props;
	const handleNavigation = (route: string): void => {
		history.push(route);
	};

	useEffect(() => {
		if (showSignUp) {
			login({
				initialScreen: 'signUp'
			});
		} else if (showLogIn) {
			login({
				initialScreen: 'login'
			});
		}
	}, []);

	const logoSize = useMediaQuery('(max-width:599px)')
		? {
				width: 122,
				height: 49
		  }
		: {
				width: 226,
				height: 90
		  };

	return (
		<Section>
			<div className={classes.container}>
				<Grid container spacing={24}>
					<Grid item xs={12} sm={4} className={classes.logoContainer}>
						<Link to={'/'}>
							<Logo {...logoSize} />
						</Link>
					</Grid>
					{!disableButtons && (
						<Grid item xs={12} sm={8} className={classes.buttonContainer}>
							{!isAuthenticated && (
								<Fragment>
									<Button className={classes.button} onClick={logout}>
										Enter 4 digit code
									</Button>
									<Button className={classes.button} onClick={() => login()}>
										Sign in
									</Button>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() => {
											handleNavigation(publicRoutes.onboarding.route);
										}}
									>
										Start Fundraiser
									</Button>
								</Fragment>
							)}
							{isAuthenticated && (
								<Fragment>
									<Button className={classes.button} onClick={logout}>
										Enter 4 digit code
									</Button>
									<Button className={classes.button} onClick={logout}>
										Sign out
									</Button>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() => {
											handleNavigation(privateRoutes.dashboard.route);
										}}
									>
										Dashboard
									</Button>
								</Fragment>
							)}
						</Grid>
					)}
				</Grid>
			</div>
		</Section>
	);
};

const customSyles = (theme: Theme): any => {
	return {
		container: {
			padding: '18px 0'
		},
		button: {
			textTransform: 'none',
			margin: theme.spacing.unit,
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
				margin: '0 2px'
			},
			[theme.breakpoints.up('sm')]: {
				marginTop: '35px'
			}
		},
		logoContainer: {
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		},
		buttonContainer: {
			textAlign: 'right',
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		}
	};
};

const mapStateToProps = (state: AppState): any => {
	return {
		isAuthenticated: authMembers.selectors.selectIsAuthenticated(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			login: authMembers.thunks.executeLogin,
			logout: authMembers.thunks.executeLogout
		},
		dispatch
	);
};

export default flow([
	withRouter,
	withStyles(customSyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Header);
