import { AppState } from '../../index';
import { STATE_KEY } from './constants';
import { ActiveDonation, ActiveDonationState } from './model';

const selectActiveDonation = (state: AppState): ActiveDonationState => state[STATE_KEY];
export const selectActiveDonationRequestInFlight = (state: AppState): boolean => selectActiveDonation(state).status.inFlight;
export const selectActiveDonationRequestSuccess = (state: AppState): boolean => selectActiveDonation(state).status.success;
export const selectActiveDonationRequestFailed = (state: AppState): boolean => !!selectActiveDonation(state).status.error;

export const selectActiveStats = (state: AppState): ActiveDonation => selectActiveDonation(state).stats;
