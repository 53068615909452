export const FUNDRAISER_FAILURE = 'fundraiser/FUNDRAISER_FAILURE';
export const FUNDRAISER_LIST_FAILURE = 'fundraiser/FUNDRAISER_LIST_FAILURE';
export const FUNDRAISER_LIST_REQUEST = 'fundraiser/FUNDRAISER_LIST_REQUEST';
export const FUNDRAISER_LIST_SUCCESS = 'fundraiser/FUNDRAISER_LIST_SUCCESS';
export const FUNDRAISER_METRICS_FAILURE = 'fundraiser/FUNDRAISER_METRICS_FAILURE';
export const FUNDRAISER_METRICS_REQUEST = 'fundraiser/FUNDRAISER_METRICS_REQUEST';
export const FUNDRAISER_METRICS_SUCCESS = 'fundraiser/FUNDRAISER_METRICS_SUCCESS';
export const FUNDRAISER_REQUEST = 'fundraiser/FUNDRAISER_REQUEST';
export const FUNDRAISER_STATUS_RESET = 'fundraiser/FUNDRAISER_STATUS_RESET';
export const FUNDRAISER_SUCCESS = 'fundraiser/FUNDRAISER_SUCCESS';
export const SET_FUNDRAISER_VALUES = 'fundraiser/SET_FUNDRAISER_VALUES';

export default {
	FUNDRAISER_FAILURE,
	FUNDRAISER_LIST_FAILURE,
	FUNDRAISER_LIST_REQUEST,
	FUNDRAISER_LIST_SUCCESS,
	FUNDRAISER_REQUEST,
	FUNDRAISER_STATUS_RESET,
	FUNDRAISER_SUCCESS,
	SET_FUNDRAISER_VALUES,
	FUNDRAISER_METRICS_FAILURE,
	FUNDRAISER_METRICS_REQUEST,
	FUNDRAISER_METRICS_SUCCESS
};
