import { Theme, withStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';

import { images } from './assets';
import Slide from './slide';

interface Props {
	classes: any;
}

const LOOP_INTERVAL_MS = 10000;

const FundraisingCarousel = (props: Props): ReactElement => {
	const { classes } = props;
	const [currentIndex, updateIndex] = useState(0);

	useEffect(() => {
		let timeout = setTimeout(() => {
			if (currentIndex < images.length - 1) {
				updateIndex(currentIndex + 1);
			} else {
				// Loop back to start of images.
				updateIndex(0);
			}
		}, LOOP_INTERVAL_MS);
		return () => clearTimeout(timeout);
	}, [currentIndex]);

	return (
		<div className={classes.container}>
			<div className={classes.sideImages}>
				{images.map(
					(imageAsset, index): ReactElement => {
						const adjustedIndex = currentIndex - 1;
						const myIndex = adjustedIndex < 0 ? images.length - 1 : adjustedIndex;

						return <Slide image={imageAsset.secondary} isCurrent={index === myIndex} key={index} />;
					}
				)}
			</div>
			<div className={classes.picCenter}>
				{images.map((imageAsset, index) => {
					return (
						<Slide
							image={imageAsset.primary}
							isCurrent={index === currentIndex}
							key={index}
							header={imageAsset.header}
							text={imageAsset.text}
						/>
					);
				})}
			</div>
			<div className={classes.sideImages} style={{ right: 0 }}>
				{images.map((imageAsset, index) => {
					const adjustedIndex = currentIndex + 1;
					const myIndex = adjustedIndex === images.length ? 0 : adjustedIndex;
					return <Slide image={imageAsset.secondary} isCurrent={index === myIndex} key={index} />;
				})}
			</div>
		</div>
	);
};

const styles = (theme: Theme): any => {
	return {
		sideImages: {
			borderRadius: '5px',
			marginTop: '100px',
			position: 'absolute',
			width: '350px',
			height: '250px',
			zIndex: 1,
			[theme.breakpoints.down('xs')]: {
				display: 'none'
			},
			[theme.breakpoints.up('md')]: {
				width: '280px',
				height: '200px'
			},
			[theme.breakpoints.up('lg')]: {
				width: '350px',
				height: '250px'
			}
		},
		picCenter: {
			borderRadius: '5px',
			[theme.breakpoints.up('xs')]: {
				width: '370px',
				height: '244px'
			},
			[theme.breakpoints.up('sm')]: {
				width: '640px',
				height: '424px'
			},
			[theme.breakpoints.up('md')]: {
				width: '800px',
				height: '530px'
			},
			zIndex: 5,
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
			boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.34)'
		},
		container: {
			position: 'relative',
			width: '100%',
			[theme.breakpoints.up('xs')]: {
				height: '244px'
			},
			[theme.breakpoints.up('sm')]: {
				height: '424px'
			},
			[theme.breakpoints.up('md')]: {
				height: '530px'
			}
		}
	};
};

export default withStyles(styles)(FundraisingCarousel);
