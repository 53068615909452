import { Typography } from '@material-ui/core';
import React, { Fragment, ReactElement } from 'react';

const ComingSoon = (): ReactElement => {
	return (
		<Fragment>
			<Typography paragraph>Coming Soon!</Typography>
		</Fragment>
	);
};

export default ComingSoon;
