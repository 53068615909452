import { Checkbox, FormControlLabel, Link, Typography } from '@material-ui/core';
import { History } from 'history';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Section from '../../../component/containers/section';
import Header from '../../../component/header';
import LoaderButton from '../../../component/loaderButton';
import privateRoutes from '../../../core/routes/private';
import publicRoutes from '../../../core/routes/public';
import { AppState } from '../../../store';
import authMembers from '../../../store/modules/auth';
import fundraiserMembers from '../../../store/modules/fundraiser';
import { FundrasierDTO } from '../../../store/modules/fundraiser/model';
import { login } from '../../../utility/AuthService';
import Group from './group';
import Item from './item';

interface Props {
	isAuthenticated: boolean;
	fundraiser: FundrasierDTO;
	history: History;
	inFlight: boolean;
	loginRequest(): void;
	fundraiserSuccess: boolean;
	saveFundraiser(fundraiser: FundrasierDTO): void;
}

const Summary = (props: Props): ReactElement => {
	const { fundraiserSuccess, isAuthenticated, loginRequest, saveFundraiser, history, inFlight, fundraiser } = props;

	useEffect((): void => {
		if (!fundraiser.goal) {
			history.push(publicRoutes.signUpStep.getRoute(0));
		}
	}, []);

	useEffect((): void => {
		if (fundraiserSuccess) {
			history.push(privateRoutes.dashboard.route);
		}
	}, [fundraiserSuccess]);

	const authenticate = (): void => {
		loginRequest();
		login();
	};

	const startFundraiser = (): void => {
		saveFundraiser(fundraiser);
	};

	// formats the number to look nice and clean.
	const fundraiserValue = fundraiser.goal ? fundraiser.goal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0;
	const label = (
		<div>
			By registering your account, you agree to our{' '}
			<Link href={'/terms'} target="_blank">
				Terms & Services
			</Link>{' '}
			and the{' '}
			<Link href={'https://stripe.com/en-fr/connect-account/legal'} target="_blank">
				Stripe Connected Account Agreement
			</Link>
		</div>
	);

	return (
		<div>
			<Header disableButtons={true} />
			<Section>
				<div
					style={{
						width: '80%'
					}}
				>
					<Group
						stepNumber={'1'}
						bgColor={'#F8FCFA'}
						bgAvatarColor={'#77C49E'}
						location={publicRoutes.signUpStep.getRoute(0)}
						title={'Fundraiser Goal & Nonprofit Name'}
					>
						<Item label={'Goal'} description={`$${fundraiserValue}`} />
						<Item label={'Non Profit'} description={fundraiser.charityName} />
					</Group>
					<Group
						gutterTop
						stepNumber={'2'}
						bgColor={'#FDFCF3'}
						bgAvatarColor={'#E7C313'}
						location={publicRoutes.signUpStep.getRoute(1)}
						title={'Category, title & description'}
					>
						<Item label={'Title'} description={fundraiser.title} />
						<Item label={'Duration'} description={fundraiser.description} />
					</Group>
					<Group
						gutterTop
						stepNumber={'3'}
						bgColor={'#FDF8F3'}
						bgAvatarColor={'#E46003'}
						location={publicRoutes.signUpStep.getRoute(2)}
						title={'Settings'}
					>
						<Item label={'Fundraiser category'} labelMDGridSize={3} description={fundraiser.category} />
						<Item label={"Fundraiser's zip code"} labelMDGridSize={3} description={fundraiser.zipCode} />
					</Group>
					<div style={{ margin: '25px 50px' }}>
						<Typography variant="body1">
							You will be prompted to enter donation withdrawl information after signing in.
						</Typography>
						<Typography variant="body1">
							We use{' '}
							<Link href={'https://stripe.com/en-fr/connect-account/legal'} target="_blank">
								Stripe
							</Link>{' '}
							to process all transactions. By creating a fundraiser, we create a free stripe account on your behalf
							to hold all the donation funds until dispersement.
						</Typography>
						<FormControlLabel control={<Checkbox value="tosAccept" />} label={label} />
						{!isAuthenticated && <LoaderButton className="btn-margin" onClick={authenticate} label={'Sign In'} />}
						{isAuthenticated && (
							<Fragment>
								<LoaderButton
									className="btn-margin"
									onClick={startFundraiser}
									label={'Start my fundraiser'}
									loading={inFlight}
								/>
							</Fragment>
						)}
					</div>
				</div>
			</Section>
		</div>
	);
};

const mapStateToProps = (state: AppState): any => {
	return {
		isAuthenticated: authMembers.selectors.selectIsAuthenticated(state),
		fundraiser: fundraiserMembers.selectors.selectFundraiserData(state),
		fundraiserSuccess: fundraiserMembers.selectors.selectFundraiserRequestSuccess(state),
		inFlight: fundraiserMembers.selectors.selectFundraiserRequestInFlight(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			loginRequest: authMembers.actions.loginRequest,
			saveFundraiser: fundraiserMembers.thunks.saveFundraiser
		},
		dispatch
	);
};

export default flow([
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Summary);
