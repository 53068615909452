import React from 'react';

import ComingSoon from '../../page/private/comingSoon';
import Dashboard from '../../page/private/dashboard';
import Donations from '../../page/private/donations';
import OrganizationStats from '../../page/private/organizationStats';

export default Object.freeze({
	dashboard: {
		route: '/dashboard',
		label: 'Dashboard',
		getComponent: () => {
			return <Dashboard />;
		},
		getRoute: () => {
			return '/dashboard';
		}
	},
	fundraisers: {
		route: '/dashboard/fundraisers',
		label: 'Test',
		getComponent: () => {
			return <ComingSoon />;
		},
		getRoute: () => {
			return '/dashboard/fundraisers';
		}
	},
	organizationStats: {
		route: '/dashboard/organizations/:id',
		label: 'Test',
		getComponent: () => {
			return <OrganizationStats />;
		},
		getRoute: (id: string) => {
			return `/dashboard/organizations/${id}`;
		}
	},
	marketing: {
		route: '/dashboard/marketing',
		label: 'Test',
		getComponent: () => {
			return <ComingSoon />;
		},
		getRoute: () => {
			return '/dashboard/marketing';
		}
	},
	statusAwards: {
		route: '/dashboard/status',
		label: 'Test',
		getComponent: () => {
			return <ComingSoon />;
		},
		getRoute: () => {
			return '/dashboard/status';
		}
	},
	settings: {
		route: '/dashboard/settings',
		label: 'Test',
		getComponent: () => {
			return <ComingSoon />;
		},
		getRoute: () => {
			return '/dashboard/settings';
		}
	},
	donations: {
		route: '/dashboard/donations',
		label: 'My donations',
		getComponent: () => {
			return <Donations />;
		},
		getRoute: () => {
			return '/dashboard/donations';
		}
	}
});
