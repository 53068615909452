export const ORGANIZATION_LIST_FAILURE = 'organization/ORGANIZATION_LIST_FAILURE';
export const ORGANIZATION_LIST_REQUEST = 'organization/ORGANIZATION_LIST_REQUEST';
export const ORGANIZATION_LIST_SUCCESS = 'organization/ORGANIZATION_LIST_SUCCESS';
export const ORGANIZATION_LIST_CLEAR = 'organization/ORGANIZATION_LIST_CLEAR';
export const ORGANIZATION_METRICS_REQUEST = 'organization/ORGANIZATION_METRICS_REQUEST';
export const ORGANIZATION_METRICS_SUCCESS = 'organization/ORGANIZATION_METRICS_SUCCESS';
export const ORGANIZATION_METRICS_FAILURE = 'organization/ORGANIZATION_METRICS_FAILURE';

export default {
	ORGANIZATION_LIST_CLEAR,
	ORGANIZATION_LIST_FAILURE,
	ORGANIZATION_LIST_REQUEST,
	ORGANIZATION_LIST_SUCCESS,
	ORGANIZATION_METRICS_FAILURE,
	ORGANIZATION_METRICS_REQUEST,
	ORGANIZATION_METRICS_SUCCESS
};
