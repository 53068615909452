import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, ReactElement } from 'react';

import { PublicDonation } from '../../../../store/modules/donation/model';
import { formatValueWithCurrency } from '../../../../utility/currency';

interface Props {
	classes: any;
	donations: PublicDonation[];
}

const DonationList = (props: Props): ReactElement => {
	return (
		<Fragment>
			<Typography variant="h5" gutterBottom>
				Donations you've made
			</Typography>
			<Typography variant="body2">
				Listed below are the donations you've made, how much you've tipped and transaction ID's for each of the donations.
			</Typography>
			<Paper className={props.classes.root}>
				<Table className={props.classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Fundraiser Name</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Donated</TableCell>
							<TableCell>Tipped</TableCell>
							<TableCell>Transaction ID</TableCell>
							<TableCell>Total Paid</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.donations.map(donation => (
							<TableRow hover key={donation.stripeChargeIdentifier}>
								<TableCell>{donation.fundraiser ? donation.fundraiser.title : 'Unknown'}</TableCell>
								<TableCell>{moment(donation.createdAt).format('LLL')}</TableCell>
								<TableCell>{formatValueWithCurrency(donation.amount / 100)}</TableCell>
								<TableCell>{formatValueWithCurrency(donation.tipAmount / 100)}</TableCell>
								<TableCell>{donation.stripeChargeIdentifier}</TableCell>
								<TableCell>{formatValueWithCurrency((donation.tipAmount + donation.amount) / 100)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		</Fragment>
	);
};

const styles = (theme: Theme): any => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto'
	},
	table: {
		minWidth: 700
	}
});

export default withStyles(styles)(DonationList);
