import { Reducer } from 'redux';

import { errorStatus, requestStatus, successStatus } from '../../common/requestStandards';
import { Action } from '../../common/typesInterface';
import { FUNDRAISER_LIST_SUCCESS } from '../fundraiser/actionTypes';
import { Fundraiser } from '../fundraiser/model';
import ActionTypes from './actionTypes';
import { Organization, OrganizationState } from './model';

export const initialState: OrganizationState = {
	status: {
		error: undefined,
		inFlight: false,
		success: false
	},
	organizationList: {},
	organizationFundraisers: {}
};

const reducer: Reducer<OrganizationState> = (state = initialState, action: Action): OrganizationState => {
	switch (action.type) {
		case ActionTypes.ORGANIZATION_LIST_REQUEST:
			return {
				...state,
				status: {
					...requestStatus
				}
			};
		case ActionTypes.ORGANIZATION_LIST_SUCCESS:
			return {
				...state,
				status: {
					...successStatus
				},
				organizationList: action.data.reduce((prev: any, current: Organization) => {
					return { ...prev, [current.publicId]: current };
				}, {})
			};
		case ActionTypes.ORGANIZATION_LIST_FAILURE:
			return {
				...state,
				status: {
					...errorStatus,
					error: action.error
				}
			};
		case ActionTypes.ORGANIZATION_METRICS_SUCCESS:
			return {
				...state,
				status: {
					...successStatus
				},
				organizationList: {
					...state.organizationList,
					[action.data.publicId]: {
						...state.organizationList[action.data.publicId],
						...action.data
					}
				}
			};
		case FUNDRAISER_LIST_SUCCESS: {
			return {
				...state,
				organizationFundraisers: {
					...state.organizationFundraisers,
					[action.data.publicId]: action.data.fundraisers.map((fundraiser: Fundraiser) => fundraiser.publicId)
				}
			};
		}
		case ActionTypes.ORGANIZATION_LIST_CLEAR:
			return { ...initialState };
		default: {
			return state;
		}
	}
};

export default reducer;
