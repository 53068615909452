import MomentUtils from '@date-io/moment';
import blue from '@material-ui/core/colors/blue';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Router from './core/router';
import configureStore from './store/configureStore';
import request from './utility/request';

const store = configureStore({
	service: request
});
const rootEl = document.getElementById('root');

const theme = createMuiTheme({
	palette: {
		primary: blue,
		background: {
			default: '#ffffff'
		}
	},
	typography: {
		useNextVariants: true
	}
});

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Provider store={store}>
				<Router />
			</Provider>
		</MuiPickersUtilsProvider>
	</MuiThemeProvider>,
	rootEl
);
// eslint-disable-next-line no-undef
// if (module.hot) {
// 	// eslint-disable-next-line no-undef
// 	module.hot.accept('./core/router', () => {
// 		const NextApp = require('./core/router').default;
// 		ReactDOM.render(
// 			<MuiThemeProvider theme={theme}>
// 				<NextApp />
// 			</MuiThemeProvider>,
// 			rootEl
// 		);
// 	});
// }
