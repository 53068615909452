export const rootLabel = {
	fontSize: '25px',
	fontWeight: 300,
	color: '#000000'
};

export const rootNormalInput = {
	paddingTop: '10px',
	fontSize: '30px',
	fontWeight: 300
};

export const section = {
	padding: '35px 100px',
	backgroundColor: '#F5FFF9'
};

export const textField = {
	margin: '40px 0'
};
