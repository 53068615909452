import { AppState } from '../../index';
import { STATE_KEY } from './constants';
import { DonationState, PublicDonation } from './model';

// Creating the Array ahead of time helps reduce re-renders
// downstream.
const ARRAY_CONSTANT: never[] | PublicDonation[] = [];

const selectDonation = (state: AppState): DonationState => state[STATE_KEY];
export const selectDonationInFlight = (state: AppState): boolean => selectDonation(state).status.inFlight;
export const selectDonationList = (state: AppState): PublicDonation[] => {
	const donationState = selectDonation(state);
	return donationState.donationList ? Object.values(donationState.donationList) : ARRAY_CONSTANT;
};
