import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { PublicDonation } from './model';

export const donationRequest = (): Action => ({
	type: t.DONATION_REQUEST
});

export const donationSuccess = (donations: PublicDonation[]): Action => ({
	type: t.DONATION_SUCCESS,
	data: donations
});

export const donationFailure = (error: Error): Action => ({
	type: t.DONATION_FAILURE,
	error
});
