import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import FormatNumber from '../../../../component/numberFormat';
import { AppState } from '../../../../store';
import { AggregateMetric } from '../../../../store/common/metrics';
import organizationMembers from '../../../../store/modules/organization';
import { OrganizationMetrics } from '../../../../store/modules/organization/model';

interface Props {
	classes: any;
	getOrganizationMetrics(publicId: string): void;
	organizationPublicId: string;
	metrics: OrganizationMetrics;
}

class OrgTable extends PureComponent<Props> {
	public componentWillMount(): void {
		this.props.getOrganizationMetrics(this.props.organizationPublicId);
	}

	public componentDidUpdate(prevProps: Props): void {
		if (prevProps.organizationPublicId !== this.props.organizationPublicId) {
			this.props.getOrganizationMetrics(this.props.organizationPublicId);
		}
	}

	public render(): ReactElement {
		const { classes, metrics } = this.props;
		if (!metrics) {
			return <div>Loading</div>;
		}

		const createRow = (label: string, aggregateStats: AggregateMetric): ReactElement => {
			return (
				<TableRow>
					<TableCell component="th" scope="row" className={classes.tableHeader}>
						{label}
					</TableCell>
					<TableCell align="right" className={classes.tableValues}>
						{FormatNumber(aggregateStats.today, aggregateStats.typeAdjustment)}
					</TableCell>
					<TableCell align="right" className={classes.tableValues}>
						{FormatNumber(aggregateStats.allTime, aggregateStats.typeAdjustment)}
					</TableCell>
				</TableRow>
			);
		};

		return (
			<Grid container className={classes.gridContainer}>
				<Grid item sm={8}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell> </TableCell>
								<TableCell align="right" className={classes.tableHeader}>
									Today
								</TableCell>
								<TableCell align="right" className={classes.tableHeader}>
									All-time total
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{createRow('Donations', metrics.donations)}
							{createRow('Donors', metrics.donors)}
						</TableBody>
					</Table>
				</Grid>
				<Grid item sm={4}>
					<div className={classes.aggragateStats} style={{}}>
						<Typography variant="body2">Average donation</Typography>
						<Typography variant="h4" gutterBottom>
							$0.00
						</Typography>
						<Typography variant="body2">Average donation</Typography>
						<Typography variant="h4" gutterBottom>
							$0.00
						</Typography>
						<Typography variant="body2">Average donation</Typography>
						<Typography variant="h4" gutterBottom>
							$0.00
						</Typography>
					</div>
				</Grid>
			</Grid>
		);
	}
}

const styles = (): any => ({
	aggragateStats: {
		textAlign: 'center',
		backgroundColor: 'rgba(103,186,140,.1)',
		padding: '18px',
		maxWidth: '260px',
		margin: '15px auto'
	},
	gridContainer: {
		backgroundColor: 'rgba(103, 186, 140, .1)',
		borderLeft: '1px solid #67BA8C'
	},
	tableHeader: {
		fontSize: '18px',
		fontWeight: 300
	},
	tableValues: {
		fontSize: '18px'
	}
});

const mapStateToProps = (state: AppState, ownProps: Props): any => {
	return {
		metrics: organizationMembers.selectors.selectOrganizationMetricsById(state, ownProps.organizationPublicId)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			getOrganizationMetrics: organizationMembers.thunks.getOrganizationMetrics
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(OrgTable);
