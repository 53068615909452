export const PUBLIC_FUNDRAISER_FAILURE = 'publicFundraiser/PUBLIC_FUNDRAISER_FAILURE';
export const PUBLIC_FUNDRAISER_REQUEST = 'publicFundraiser/PUBLIC_FUNDRAISER_REQUEST';
export const PUBLIC_FUNDRAISER_SUCCESS = 'publicFundraiser/PUBLIC_FUNDRAISER_SUCCESS';
export const CLEAR_PUBLIC_FUNDRAISER = 'publicFundraiser/CLEAR_PUBLIC_FUNDRAISER';

export default {
	CLEAR_PUBLIC_FUNDRAISER,
	PUBLIC_FUNDRAISER_FAILURE,
	PUBLIC_FUNDRAISER_REQUEST,
	PUBLIC_FUNDRAISER_SUCCESS
};
