import { Reducer } from 'redux';

import { Action } from '../../common/typesInterface';
import ActionTypes from './actionTypes';
import { DonationList, DonationState, PublicDonation } from './model';

export const initialState: DonationState = {
	donationList: {},
	status: {
		error: undefined,
		inFlight: false,
		success: false
	}
};

const reducer: Reducer<DonationState> = (state = initialState, action: Action): DonationState => {
	switch (action.type) {
		case ActionTypes.DONATION_REQUEST:
			return {
				...state,
				status: {
					...state.status,
					inFlight: true,
					error: undefined
				}
			};
		case ActionTypes.DONATION_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				donationList: {
					...state.donationList,
					...action.data.reduce((prev: DonationList, next: PublicDonation) => {
						return { ...prev, [next.publicId]: next };
					}, {})
				}
			};
		case ActionTypes.DONATION_FAILURE:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					error: action.error
				}
			};

		default: {
			return state;
		}
	}
};

export default reducer;
