import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../..';
import { METHODS } from '../service/model';
import { selectRequestor } from '../service/selectors';
import { publicDonationFailure, publicDonationRequest, publicDonationSuccess } from './actions';
import { DonationSuccessDTO, PublicDonationDTO } from './model';
import { selectPublicDonationRequestInFlight } from './selectors';

export const postDonation = (donation: PublicDonationDTO): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectPublicDonationRequestInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);

		dispatch(publicDonationRequest());
		try {
			const requestResponse: AxiosResponse<DonationSuccessDTO> = await requestor.executeApi({
				endpoint: '/donation',
				method: METHODS.POST,
				data: donation
			});
			dispatch(publicDonationSuccess(requestResponse.data));
		} catch (e) {
			dispatch(publicDonationFailure(e));
		}
	};
};
