import { Theme, withStyles } from '@material-ui/core';
import React, { PureComponent, ReactElement } from 'react';

import { TypeAdjustment } from '../../store/common/metrics';
import FormatNumber from '../numberFormat';

interface Props {
	classes: any;
	totalDonated: number;
	primaryColor: string;
	secondaryColor: string;
	goal: number;
	numberDonors: number;
	useSmall?: boolean;
}

class DonationBar extends PureComponent<Props> {
	private calcPercent = (donated: number, total: number): number => {
		if (donated === 0) {
			return 0;
		} else if (donated >= total) {
			return 100;
		}
		return (donated / total) * 100;
	};

	public render(): ReactElement {
		const { classes, totalDonated, primaryColor, secondaryColor, goal, numberDonors } = this.props;

		const percent = this.calcPercent(totalDonated, goal);
		const contentContainer = percent > 50 ? classes.contentRight : classes.contentLeft;

		const getClasses = (className: string): string => {
			if (this.props.useSmall) {
				return [classes[className], classes[`${className}Small`]].join(' ');
			}
			return classes[className];
		};

		return (
			<div className={getClasses('donationBar')}>
				<div
					className={getClasses('donationAmountContainer')}
					style={{ left: `${percent}%`, borderColor: secondaryColor }}
				>
					<div className={contentContainer}>
						<div className={getClasses('donationAmount')}>{FormatNumber(totalDonated, TypeAdjustment.price)}</div>
						<div className={getClasses('donationData')} style={{ minWidth: '200px' }}>
							{FormatNumber(numberDonors, TypeAdjustment.number)} donors
							<span className={classes.avgLabel}>
								{' '}
								&#183; Avg. {FormatNumber(totalDonated / numberDonors, TypeAdjustment.price, true)}/donor
							</span>
						</div>
					</div>
				</div>
				<div className={getClasses('donationBase')} style={{ backgroundColor: primaryColor }}>
					<div className={getClasses('completed')} style={{ width: `${percent}%`, backgroundColor: secondaryColor }} />
				</div>
				<div className={classes.donationValues}>
					<div className={classes.donationStart} style={{ borderColor: primaryColor }} />
					<div className={classes.donationComplete} style={{ borderColor: primaryColor }}>
						Goal: {FormatNumber(goal, TypeAdjustment.price)}
					</div>
				</div>
			</div>
		);
	}
}

const styles = (theme: Theme): any => {
	return {
		avgLabel: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		donationBar: {
			paddingTop: '100px',
			position: 'relative',
			margin: '2vw',
			overflow: 'hidden'
		},
		donationBarSmall: {
			paddingTop: '40px'
		},
		contentLeft: {
			marginLeft: '15px',
			position: 'absolute',
			left: '100%',
			width: '100vw',
			[theme.breakpoints.down('sm')]: {
				marginLeft: '5px'
			}
		},
		contentRight: {
			textAlign: 'right',
			marginRight: '15px',
			position: 'absolute',
			right: '100%',
			width: '100vw'
		},
		donationBase: {
			width: '100%',
			height: '110px',
			padding: '22px 0'
		},
		donationBaseSmall: {
			height: '31px',
			padding: '5px 0'
		},
		completed: {
			transition: 'width .4s ease-in-out',
			height: '66px'
		},
		completedSmall: {
			height: '20px'
		},
		donationAmountContainer: {
			top: 0,
			height: '188px',
			position: 'absolute',
			transition: 'left .4s ease-in-out',
			borderLeft: '2px solid',
			[theme.breakpoints.down('sm')]: {
				paddingTop: '15px'
			}
		},
		donationAmountContainerSmall: {
			height: '65px',
			paddingTop: 0
		},
		donationAmount: {
			fontSize: '50px',
			fontWeight: '500',
			[theme.breakpoints.down('sm')]: {
				fontSize: '25px'
			}
		},
		donationAmountSmall: {
			fontSize: '25px',
			display: 'inline-block',
			paddingRight: '10px',
			[theme.breakpoints.down('sm')]: {
				paddingRight: 0,
				fontSize: '16px',
				display: 'block'
			}
		},
		donationData: {
			fontSize: '20px',
			fontWeight: '300',
			paddingTop: '10px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px'
			}
		},
		donationDataSmall: {
			display: 'inline-block',
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				paddingTop: 0
			}
		},
		donationValues: {
			height: '50px',
			width: '100%',
			position: 'relative'
		},
		donationStart: {
			position: 'absolute',
			left: 0,
			padding: '10px 5px',
			fontSize: '20px',
			fontWeight: '300',
			borderLeft: '2px solid'
		},
		donationComplete: {
			position: 'absolute',
			right: 0,
			top: 0,
			padding: '10px 5px',
			fontSize: '20px',
			fontWeight: '300',
			borderRight: '2px solid',
			textAlign: 'right'
		}
	};
};

export default withStyles(styles)(DonationBar);
