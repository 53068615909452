import { Typography, withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../core/svgAssets/4givingFullLogo.svg';

interface Props {
	classes: any;
}

const TermsOfService = (props: Props): ReactElement => {
	const { classes } = props;
	return (
		<div className={classes.container}>
			<div className={classes.logo}>
				<Link to={'/'}>
					<Logo width={226} height={90} />
				</Link>
			</div>
			<div>
				<Typography variant={'h4'}>Terms of Service</Typography>
				<Typography>This is the terms of service laid out by the 4giving application.</Typography>
			</div>
		</div>
	);
};

const styles = (): any => ({
	container: {
		maxWidth: 800,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	logo: {
		paddingTop: 30,
		paddingBottom: 30,
		textAlign: 'center'
	}
});

export default withStyles(styles)(TermsOfService);
