import { FundrasierDTO } from '../../../../../store/modules/fundraiser/model';
import { isSet } from '../../../../../utility/formHelpers';

export interface FundraiserBrandingValidationErrors {
	title?: string;
	description?: string;
}

export const validateBranding = ({ title, description }: FundrasierDTO): FundraiserBrandingValidationErrors => {
	const errors: FundraiserBrandingValidationErrors = {};
	if (!isSet(title)) {
		errors.title = 'Please enter a title';
	}
	if (!isSet(description)) {
		errors.description = 'Please enter a description for your fundraiser';
	}

	return errors;
};
