import { Reducer } from 'redux';

import { Action } from '../../common/typesInterface';
import ActionTypes from './actionTypes';
import { CharityState } from './model';

export const initialState: CharityState = {
	charityList: [],
	status: {
		error: undefined,
		inFlight: false,
		success: false
	}
};

const reducer: Reducer<CharityState> = (state = initialState, action: Action): CharityState => {
	switch (action.type) {
		case ActionTypes.CHARITY_REQUEST:
			return {
				...state,
				status: {
					...state.status,
					inFlight: true,
					error: undefined
				}
			};
		case ActionTypes.CHARITY_SUCCESS:
			return {
				...state,
				charityList: action.data,
				status: {
					...state.status,
					inFlight: false,
					success: true
				}
			};
		case ActionTypes.CHARITY_FAILURE:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					error: action.error
				}
			};

		default: {
			return state;
		}
	}
};

export default reducer;
