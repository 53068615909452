import fundraiseEasyGray from './fundraise-easy-gray.jpg';
import fundraiseEasy from './fundraise-easy.jpg';
import momentsGray from './moments-generosity-gray.jpg';
import moments from './moments-generosity.jpg';
import organizationFocusGray from './organization-focus-gray.jpg';
import organizationFocus from './organization-focus.jpg';
import pagesWithBrandGray from './pages-with-brand-gray.jpg';
import pagesWithBrand from './pages-with-brand.jpg';
import receiptsGray from './receipts-gray.jpg';
import receipts from './receipts.jpg';

export const images = [
	{
		primary: fundraiseEasy,
		secondary: fundraiseEasyGray,
		header: 'Fundraise the easy way',
		text: 'Enter the amount to raise, basic information & your fundraiser is ready in as little as 5 minutes!'
	},
	{
		primary: moments,
		secondary: momentsGray,
		header: 'Encourage moments of generosity',
		text: 'Our easy fundraising platform allows supporters to donate anytime, anywhere.'
	},
	{
		primary: organizationFocus,
		secondary: organizationFocusGray,
		header: 'Fundraising pages with your brand',
		text: 'Mobile & desktop fundraising pages encourage donations - just add your info!'
	},
	{
		primary: pagesWithBrand,
		secondary: pagesWithBrandGray,
		header: 'More time to focus on your organization',
		text: 'Simple fundraiser marketing gives you more time focusing on the organization you love.'
	},
	{
		primary: receipts,
		secondary: receiptsGray,
		header: 'Instant donor receipts',
		text: 'Your donors receive donation receipts immediately by text or email.'
	}
];
