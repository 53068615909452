import { Reducer } from 'redux';

import { Action } from '../../common/typesInterface';
import ActionTypes from './actionTypes';
import { PublicFundraiserState } from './model';

export const initialState: PublicFundraiserState = {
	publicFundraiser: {},
	status: {
		error: undefined,
		inFlight: false,
		success: false
	}
};

const reducer: Reducer<PublicFundraiserState> = (state = initialState, action: Action): PublicFundraiserState => {
	switch (action.type) {
		case ActionTypes.PUBLIC_FUNDRAISER_REQUEST:
			return {
				...state,
				status: {
					...state.status,
					inFlight: true,
					error: undefined
				}
			};
		case ActionTypes.PUBLIC_FUNDRAISER_SUCCESS:
			return {
				...state,
				publicFundraiser: action.data,
				status: {
					...state.status,
					inFlight: false,
					success: true
				}
			};
		case ActionTypes.PUBLIC_FUNDRAISER_FAILURE:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					error: action.error
				}
			};
		case ActionTypes.CLEAR_PUBLIC_FUNDRAISER:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
