import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import FormatNumber from '../../../../component/numberFormat';
import { AppState } from '../../../../store';
import { AggergateMetricExtra } from '../../../../store/common/metrics';
import fundraiserMembers from '../../../../store/modules/fundraiser';
import { FundraiserMetrics } from '../../../../store/modules/fundraiser/model';

interface Props {
	classes: any;
	visible: boolean;
	getFundraiserMetrics(publicId: string): void;
	publicId: string;
	metrics?: FundraiserMetrics;
}

class FundraiserStats extends PureComponent<Props> {
	public componentDidUpdate(): void {
		if (this.props.visible && !this.props.metrics) {
			this.props.getFundraiserMetrics(this.props.publicId);
		}
	}

	private createRow = (label: string, aggregateStats: AggergateMetricExtra): ReactElement => {
		return (
			<TableRow>
				<TableCell component="th" scope="row" className={this.props.classes.tableHeader}>
					{label}
				</TableCell>
				<TableCell align="right" className={this.props.classes.tableValues}>
					{FormatNumber(aggregateStats.today, aggregateStats.typeAdjustment)}
				</TableCell>
				<TableCell align="right" className={this.props.classes.tableValues}>
					{FormatNumber(aggregateStats.yesterday, aggregateStats.typeAdjustment)}
				</TableCell>
				<TableCell align="right" className={this.props.classes.tableValues}>
					{FormatNumber(aggregateStats.lastSeven, aggregateStats.typeAdjustment)}
				</TableCell>
				<TableCell align="right" className={this.props.classes.tableValues}>
					{FormatNumber(aggregateStats.allTime, aggregateStats.typeAdjustment)}
				</TableCell>
			</TableRow>
		);
	};

	public render(): ReactElement | null {
		if (!this.props.visible || !this.props.metrics) {
			return null;
		}
		return (
			<Table className={this.props.classes.gridContainer}>
				<TableHead>
					<TableRow>
						<TableCell> </TableCell>
						<TableCell align="right" className={this.props.classes.tableHeader}>
							Today
						</TableCell>
						<TableCell align="right" className={this.props.classes.tableHeader}>
							Yesterday
						</TableCell>
						<TableCell align="right" className={this.props.classes.tableHeader}>
							Last 7 days
						</TableCell>
						<TableCell align="right" className={this.props.classes.tableHeader}>
							total
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{this.createRow('Donations', this.props.metrics.donations)}
					{this.createRow('Donors', this.props.metrics.donors)}
				</TableBody>
			</Table>
		);
	}
}

const styles = (): any => ({
	gridContainer: {
		backgroundColor: 'rgba(103, 186, 140, .1)',
		borderLeft: '1px solid #67BA8C'
	},
	tableHeader: {
		fontSize: '18px',
		fontWeight: 300
	},
	tableValues: {
		fontSize: '18px'
	}
});

const mapStateToProps = (state: AppState, ownProps: Props): any => {
	return {
		metrics: fundraiserMembers.selectors.selectFundraiserMetrics(state, ownProps.publicId)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			getFundraiserMetrics: fundraiserMembers.thunks.getFundraiserMetrics
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(FundraiserStats);
