import flow from 'lodash/flow';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../../../store';
import donationMembers from '../../../store/modules/donation';
import { PublicDonation } from '../../../store/modules/donation/model';
import DonationList from './donationList';

interface Props {
	getDonations(): void;
	donationList?: PublicDonation[];
	requestingDonations: boolean;
}

class Donations extends PureComponent<Props> {
	public componentWillMount(): void {
		this.props.getDonations();
	}

	public render(): ReactElement {
		if (this.props.requestingDonations) {
			return <div>Loading</div>;
		}

		if (!this.props.donationList || this.props.donationList.length === 0) {
			return <div>As soon as you start making donations, you will see them appear here.</div>;
		}

		return (
			<div>
				<DonationList donations={this.props.donationList} />
			</div>
		);
	}
}
const mapStateToProps = (state: AppState): any => {
	return {
		requestingDonations: donationMembers.selectors.selectDonationInFlight(state),
		donationList: donationMembers.selectors.selectDonationList(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			getDonations: donationMembers.thunks.getDonations
		},
		dispatch
	);
};

export default flow([
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Donations);
