import { Reducer } from 'redux';

import { Action } from '../../common/typesInterface';
import ActionTypes from './actionTypes';
import { Fundraiser, FundraiserList, FundraiserState } from './model';

export const initialState: FundraiserState = {
	editingFundraiser: {},
	fundraiserList: {},
	status: {
		error: undefined,
		inFlight: false,
		success: false
	}
};

const reducer: Reducer<FundraiserState> = (state = initialState, action: Action): FundraiserState => {
	switch (action.type) {
		case ActionTypes.FUNDRAISER_REQUEST:
		case ActionTypes.FUNDRAISER_LIST_REQUEST:
			return {
				...state,
				status: {
					...state.status,
					inFlight: true,
					error: undefined
				}
			};
		case ActionTypes.FUNDRAISER_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				editingFundraiser: {} // cleanr fundraiser on success
			};
		case ActionTypes.FUNDRAISER_LIST_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				fundraiserList: {
					...state.fundraiserList,
					...action.data.fundraisers.reduce((prev: FundraiserList, next: Fundraiser) => {
						return { ...prev, [next.publicId]: next };
					}, {})
				}
			};
		case ActionTypes.FUNDRAISER_METRICS_SUCCESS:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					success: true
				},
				fundraiserList: {
					...state.fundraiserList,
					[action.data.publicId]: {
						...state.fundraiserList[action.data.publicId],
						...action.data
					}
				}
			};
		case ActionTypes.SET_FUNDRAISER_VALUES:
			return {
				...state,
				editingFundraiser: {
					...state.editingFundraiser,
					...action.data
				}
			};
		case ActionTypes.FUNDRAISER_FAILURE:
		case ActionTypes.FUNDRAISER_LIST_FAILURE:
			return {
				...state,
				status: {
					...state.status,
					inFlight: false,
					error: action.error
				}
			};
		case ActionTypes.FUNDRAISER_STATUS_RESET:
			return {
				...state,
				status: {
					...initialState.status
				}
			};
		default: {
			return state;
		}
	}
};

export default reducer;
