import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../..';
import { METHODS } from '../service/model';
import { selectRequestor } from '../service/selectors';
import {
	organizationListFailure,
	organizationListRequest,
	organizationListSuccess,
	organizationMetricsFailure,
	organizationMetricsRequest,
	organizationMetricsSuccess
} from './actions';
import { Organization } from './model';
import { selectOrganizationRequestInFlight } from './selectors';

export const getOrganizations = (): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectOrganizationRequestInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);

		dispatch(organizationListRequest());
		try {
			const response: AxiosResponse<Organization[]> = await requestor.executeApi({
				endpoint: '/organization',
				method: METHODS.GET
			});
			dispatch(organizationListSuccess(response.data));
		} catch (e) {
			dispatch(organizationListFailure(e));
		}
	};
};

export const getOrganizationMetrics = (orgPublicId: string): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectOrganizationRequestInFlight(appState);
		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);
		dispatch(organizationMetricsRequest());
		try {
			const response: AxiosResponse<Organization[]> = await requestor.executeApi({
				endpoint: `/organization/${orgPublicId}/stats`,
				method: METHODS.GET
			});
			dispatch(organizationMetricsSuccess({ publicId: orgPublicId, metrics: response.data }));
		} catch (e) {
			dispatch(organizationMetricsFailure(e));
		}
	};
};
