export const ACTIVE_STATS_FAILURE = 'activeDonation/ACTIVE_STATS_FAILURE';
export const ACTIVE_STATS_REQUEST = 'activeDonation/ACTIVE_STATS_REQUEST';
export const ACTIVE_STATS_SUCCESS = 'activeDonation/ACTIVE_STATS_SUCCESS';
export const CLEAR_ACTIVE_STATS = 'activeDonation/CLEAR_ACTIVE_STATS';

export default {
	CLEAR_ACTIVE_STATS,
	ACTIVE_STATS_FAILURE,
	ACTIVE_STATS_REQUEST,
	ACTIVE_STATS_SUCCESS
};
