import { ListItemIcon, ListItemText } from '@material-ui/core';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface Props extends ListItemProps {
	to: string;
	icon?: any;
	primary: string;
	secondary?: string;
}

const ListItemLink = (props: Props): ReactElement => {
	// eslint-disable-next-line react/prop-types
	const renderLink = (itemProps: any): ReactElement => <Link to={props.to} {...itemProps} />;

	const { icon, primary, secondary } = props;
	return (
		<li>
			<ListItem button component={renderLink}>
				{icon && <ListItemIcon>{icon}</ListItemIcon>}
				<ListItemText inset primary={primary} secondary={secondary} />
			</ListItem>
		</li>
	);
};

export default ListItemLink;
