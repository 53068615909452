import { AppState } from '../../index';
import { STATE_KEY } from './constants';
import { DonationSuccessDTO, PublicDonationState } from './model';

const selectPublicDonation = (state: AppState): PublicDonationState => state[STATE_KEY];
export const selectPublicDonationRequestInFlight = (state: AppState): boolean => selectPublicDonation(state).status.inFlight;
export const selectPublicDonationRequestSuccess = (state: AppState): boolean => selectPublicDonation(state).status.success;
export const selectPublicDonationRequestFailed = (state: AppState): boolean => !!selectPublicDonation(state).status.error;

export const selectPublicDonationSuccessfulDonationInformation = (state: AppState): DonationSuccessDTO | null => {
	const successfulDonation = selectPublicDonation(state).successfulDonation;
	if (successfulDonation) {
		return successfulDonation;
	}
	return null;
};

export const selectDonationTip = (state: AppState): number | undefined => selectPublicDonation(state).donation.tipValue;
