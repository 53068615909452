import { AppState } from '../../index';
import { selectOrganizationFundraiserKeys } from '../organization/selectors';
import { STATE_KEY } from './constants';
import { Fundraiser, FundraiserMetrics, FundraiserState, FundrasierDTO } from './model';

const selectFundraiser = (state: AppState): FundraiserState => state[STATE_KEY];
export const selectFundraiserRequestInFlight = (state: AppState): boolean => selectFundraiser(state).status.inFlight;
export const selectFundraiserRequestSuccess = (state: AppState): boolean => selectFundraiser(state).status.success;
export const selectFundraiserData = (state: AppState): FundrasierDTO => selectFundraiser(state).editingFundraiser;

export const selectFundraisers = (state: AppState, organizationPublicId: string): Fundraiser[] => {
	const fundraiserKeys = selectOrganizationFundraiserKeys(state, organizationPublicId);
	const fundraisers = selectFundraiser(state).fundraiserList;
	return fundraiserKeys.map((fundraiserPublicId: string) => fundraisers[fundraiserPublicId]);
};

export const selectFundraiserMetrics = (state: AppState, fundraiserPublicId: string): FundraiserMetrics | undefined => {
	const fundraiser = selectFundraiser(state).fundraiserList[fundraiserPublicId];
	if (!fundraiser) {
		return undefined;
	}
	return fundraiser.metrics;
};
