import { Button, Divider, Drawer, Icon, List, Theme, withStyles } from '@material-ui/core';
import { History } from 'history';
import flow from 'lodash/flow';
import React, { ReactElement } from 'react';
import { withRouter } from 'react-router';

import ListItemLink from '../../component/listItemLink';
import { ReactComponent as Logo } from '../../core/svgAssets/4givingFullLogo.svg';
import privateRoutes from '../routes/private';
import publicRoutes from '../routes/public';
import AppBarContainer from './privateComponents/appBar';

interface Props {
	history: History;
	classes: any;
	children: any;
}

const drawerWidth = 270;
const PrivateContainer = (props: Props): ReactElement => {
	const { classes } = props;

	return (
		<div className={classes.root}>
			<AppBarContainer />
			<Drawer
				className={classes.drawer}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper
				}}
				anchor="left"
			>
				<div className={classes.toolbar}>
					<Logo width={226} height={90} />
				</div>
				<Divider />
				<List>
					<ListItemLink
						button
						key={'Home'}
						primary={'Home'}
						to={privateRoutes.dashboard.getRoute()}
						icon={<Icon className={'fas fa-home'} />}
					/>
					<ListItemLink
						button
						key={'Donations'}
						primary={'My Donations'}
						to={privateRoutes.donations.getRoute()}
						icon={<Icon className={'fas fa-user'} />}
					/>
				</List>
				<Divider />
				<Button
					variant="contained"
					color="primary"
					fullWidth={false}
					className={classes.buttonContainer}
					onClick={() => {
						props.history.push(publicRoutes.onboarding.route);
					}}
				>
					New Fundraiser
				</Button>
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{props.children}
			</main>
		</div>
	);
};

const styles = (theme: Theme): any => ({
	root: {
		display: 'flex'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		width: drawerWidth
	},
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing.unit * 3
	},
	toolbar: {
		...theme.mixins.toolbar,
		textAlign: 'center',
		padding: '20px 0'
	},
	buttonContainer: {
		margin: '20px'
	}
});

export default flow([withStyles(styles), withRouter])(PrivateContainer);
