import { AppBar, Button, Toolbar, Typography, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../../../store';
import authMembers from '../../../store/modules/auth/index';

interface Props {
	classes: any;
	children: any;
	logout(): void;
}

const drawerWidth = 270;
const AppBarContainer = (props: Props): ReactElement => {
	return (
		<AppBar position="fixed" className={props.classes.appBar}>
			<Toolbar>
				<Typography variant="h6" color="inherit" noWrap>
					Home
				</Typography>
				<div className={props.classes.grow} />
				<Button color="inherit" onClick={props.logout}>
					Logout
				</Button>
			</Toolbar>
		</AppBar>
	);
};

const styles = (): any => ({
	grow: {
		flexGrow: 1
	},
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth
	}
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			logout: authMembers.thunks.executeLogout
		},
		dispatch
	);
};

export default flow([
	withStyles(styles),
	connect(
		null,
		mapDispatchToProps
	)
])(AppBarContainer);
