import { Avatar, Grid, Paper, Theme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { History } from 'history';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement } from 'react';
import { withRouter } from 'react-router';

import CircleAction from '../../../component/circleAction';
import Section from '../../../component/containers/section';
import FundraisingCarousel from '../../../component/fundraisingCarousel';
import Header from '../../../component/header';
import publicRoutes from '../../../core/routes/public';

interface Props {
	showSignUp?: boolean;
	showLogIn?: boolean;
	classes: any;
	history: History;
}

const Home = (props: Props): ReactElement => {
	const { classes, history } = props;

	const handleNavigation = (): void => {
		history.push(publicRoutes.onboarding.route);
	};

	return (
		<Fragment>
			<Header showSignUp={props.showSignUp} showLogIn={props.showLogIn} />
			<Section>
				<Fragment>
					<FundraisingCarousel />
					<div className={classes.buttonContainer}>
						<Button onClick={handleNavigation} variant="contained" color="primary" className={classes.button}>
							+ Start fundraising
						</Button>
					</div>
					<div className={classes.navigationContainer}>
						<CircleAction color="Green" label={'Top Fundraisers'}>
							<i className="fas fa-heart" />
						</CircleAction>
					</div>
					<div className={classes.fundraiserContainer}>
						<Grid container spacing={40}>
							<Grid item sm={6} xs={12}>
								<Paper className={classes.paper}>
									<div className={classes.imageContainer}>
										<img className={classes.fundraiserImage} src="https://picsum.photos/560/293?image=125" />
										<Avatar className={classes.avatar}>AC</Avatar>
										<div className={classes.fundraiserLabel}>The Animal Connection</div>
									</div>
								</Paper>
							</Grid>
							<Grid item sm={6} xs={12}>
								<Paper className={classes.paper}>
									<div className={classes.imageContainer}>
										<img className={classes.fundraiserImage} src="https://picsum.photos/560/293?image=136" />
										<Avatar className={classes.avatar}>RR</Avatar>
										<div className={classes.fundraiserLabel}>The Rainforest Reserve</div>
									</div>
								</Paper>
							</Grid>
							<Grid item sm={6} xs={12}>
								<Paper className={classes.paper}>
									<div className={classes.imageContainer}>
										<img className={classes.fundraiserImage} src="https://picsum.photos/560/293?image=126" />
										<Avatar className={classes.avatar}>CO</Avatar>
										<div className={classes.fundraiserLabel}>Cleanleness in the Ocean</div>
									</div>
								</Paper>
							</Grid>
							<Grid item sm={6} xs={12}>
								<Paper className={classes.paper}>
									<div className={classes.imageContainer}>
										<img className={classes.fundraiserImage} src="https://picsum.photos/560/293?image=137" />
										<Avatar className={classes.avatar}>STC</Avatar>
										<div className={classes.fundraiserLabel}>Space, the connection</div>
									</div>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</Fragment>
			</Section>
		</Fragment>
	);
};

const customStyles = (theme: Theme): any => ({
	button: {
		fontSize: '24px',
		textTransform: 'none',
		margin: theme.spacing.unit
	},
	buttonContainer: {
		textAlign: 'center',
		width: '100%',
		marginTop: '45px'
	},
	navigationContainer: {
		margin: '75px auto'
	},
	fundraiserContainer: {},
	paper: {
		padding: theme.spacing.unit,
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	fundraiserImage: {
		width: '100%',
		borderRadius: '5px'
	},
	imageContainer: {
		position: 'relative',
		marginBottom: '50px'
	},
	fundraiserLabel: {
		textAlign: 'left',
		marginLeft: '145px',
		fontSize: '25px'
	},
	avatar: {
		backgroundColor: '#fff',
		color: '#000',
		border: '1px solid rgba(0,0,0,.4)',
		position: 'absolute',
		bottom: -35,
		left: 25,
		fontSize: '35px',
		width: '100px',
		height: '100px'
	}
});

export default flow([withStyles(customStyles), withRouter])(Home);
