import { FormHelperText, Theme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { ReactElement } from 'react';

interface Props {
	error: boolean;
	errorText?: string;
	value: string;
	onChange(element: any): any;
	name?: string;
	id: string;
	classes: any;
	children: any;
}

const SelectWrapped = (props: Props): ReactElement => {
	const { classes, children, id, name, onChange, value, error, errorText } = props;

	return (
		<FormControl className={classes.formControl} error={error}>
			<Select
				native
				displayEmpty
				value={value}
				onChange={name ? onChange(name) : onChange}
				inputProps={{
					name,
					id,
					classes: {
						select: classes.label
					}
				}}
			>
				{children}
			</Select>
			{error && <FormHelperText>{errorText}</FormHelperText>}
		</FormControl>
	);
};

const customStyles = (theme: Theme): any => ({
	formControl: {
		width: '100%',
		margin: '40px 0'
	},
	label: {
		fontSize: '34px',
		lineHeight: '40px',
		fontWeight: 300,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
			lineHeight: '20px'
		}
	}
});

export default withStyles(customStyles)(SelectWrapped);
