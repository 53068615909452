import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Footer from '../../component/footer';
import styles from './publicContainer.module.scss';

const PublicContainer = props => {
	return (
		<Fragment>
			<div className={styles.container}>
				<div style={{ width: '100vw', height: '1px' }} />
				{props.children}
			</div>
			<Footer />
		</Fragment>
	);
};

PublicContainer.propTypes = {
	children: PropTypes.element
};

export default PublicContainer;
