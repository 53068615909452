import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	Typography,
	withStyles
} from '@material-ui/core';
import React, { Fragment, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import Section from '../../../../component/containers/section';
import FormatNumber from '../../../../component/numberFormat';
import { TypeAdjustment } from '../../../../store/common/metrics';
import { DonationSuccessDTO } from '../../../../store/modules/publicDonation/model';

interface Props {
	classes: any;
	donation: DonationSuccessDTO;
}

function Transition(props: any): ReactElement {
	return <Slide direction="up" {...props} />;
}

const DonationSuccess = (props: Props): ReactElement => {
	const { donation } = props;
	const [open, setDialogState] = useState(false);

	const toggleDialog = (): void => {
		setDialogState(!open);
	};

	if (!donation) {
		return <Fragment />;
	}

	return (
		<Section>
			<div className={props.classes.container}>
				<Typography variant="h4" gutterBottom>
					Thank you for your {FormatNumber(donation.amount, TypeAdjustment.price)} donation to the{' '}
					<strong>{donation.fundraiserName}</strong>!
				</Typography>
				<Typography variant="h5" gutterBottom onClick={toggleDialog} className={props.classes.view}>
					Tap here to view your donation receipt...
				</Typography>
				<Typography variant="body1" gutterBottom className={props.classes.infoText}>
					A donation receipt has been sent to {donation.email}.<br />
					To view this and other donations you have made using 4giving, please <Link to="/sign-in">
						Sign In
					</Link> or <Link to="/sign-up">Sign Up</Link> for a 4giving account.
				</Typography>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={toggleDialog}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>
						<img
							src="https://s3.amazonaws.com/assets.4giving.com/email/giving_guy_72_82_2x.png"
							width="72"
							height="82"
							alt="4Giving Guy"
						/>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Here is your donation receipt:
							<br />
							<br />
							Donor name: <strong>{donation.donorName}</strong>
							<br />
							Donor email: <strong>{donation.email}</strong>
							<br />
							Donation date @ time: <strong>{donation.time}</strong>
							<br />
							<br />
							Donation: <strong>{FormatNumber(donation.amount, TypeAdjustment.price, true)}</strong>
							<br />
							Tip: <strong>{FormatNumber(donation.tipAmount, TypeAdjustment.price, true)}</strong>
							<br />
							Total: <strong>{FormatNumber(donation.totalAmount, TypeAdjustment.price, true)}</strong>
							<br />
							Method of Payment: <strong>{donation.paymentMethod}</strong>
							<br />
							Credit Card Reference Number: <strong>{donation.transactionId}</strong>
							<br />
							<br />
							Nonprofit Organization: <strong>{donation.organizationName}</strong>
							<br />
							Nonprofit Organization EIN: <strong>{donation.organizationEin}</strong>
							<br />
							<br />
							No goods or services were provided in exchange for your contribution.
							<br />
							Your donation was made to a 501c3 organization and may be tax deductible to the extent allowed by law.
							<br />
							<br />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={toggleDialog} color="primary">
							Close
						</Button>
						<Button onClick={window.print} color="primary" variant={'contained'}>
							Print
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</Section>
	);
};

const customStyles = (): any => ({
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '80%',
		textAlign: 'center',
		maxWidth: '600px'
	},
	view: {
		marginTop: '45px',
		color: 'blue',
		cursor: 'pointer'
	},
	infoText: {
		marginTop: '45px'
	}
});

export default withStyles(customStyles)(DonationSuccess);
