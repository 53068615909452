import { Reducer } from 'redux';

import { TypeAdjustment } from '../../common/metrics';
import { errorStatus, requestStatus, successStatus } from '../../common/requestStandards';
import { Action } from '../../common/typesInterface';
import ActionTypes from './actionTypes';
import { ActiveDonationState } from './model';

export const initialState: ActiveDonationState = {
	stats: {
		donations: {
			allTime: 0,
			type: TypeAdjustment.price
		},
		donors: {
			allTime: 0,
			type: TypeAdjustment.number
		},
		goal: 0,
		leaderboard: [],
		latestDonors: []
	},
	status: {
		error: undefined,
		inFlight: false,
		success: false
	}
};

const reducer: Reducer<ActiveDonationState> = (state = initialState, action: Action): ActiveDonationState => {
	switch (action.type) {
		case ActionTypes.ACTIVE_STATS_REQUEST:
			return {
				...state,
				status: {
					...requestStatus
				}
			};
		case ActionTypes.ACTIVE_STATS_SUCCESS:
			return {
				...state,
				status: {
					...successStatus
				},
				stats: {
					goal: action.data.goal,
					...action.data.metrics
				}
			};
		case ActionTypes.ACTIVE_STATS_FAILURE:
			return {
				...state,
				status: {
					...errorStatus,
					error: action.error
				}
			};
		case ActionTypes.CLEAR_ACTIVE_STATS:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
