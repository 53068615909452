import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../..';
import { METHODS } from '../service/model';
import { selectRequestor } from '../service/selectors';
import { charityFailure, charityRequest, charitySuccess } from './actions';
import { Charity } from './model';
import { selectCharityRequestInFlight } from './selectors';

export const searchForCharities = (searchTerm: string): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectCharityRequestInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);

		dispatch(charityRequest());
		try {
			const response: AxiosResponse<Charity[]> = await requestor.executeApi({
				endpoint: '/charity',
				method: METHODS.POST,
				data: {
					searchTerm
				}
			});
			dispatch(charitySuccess(response.data));
		} catch (e) {
			dispatch(charityFailure(e));
		}
	};
};
