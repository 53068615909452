import { Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { History } from 'history';
import flow from 'lodash/flow';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import SelectWrapped from '../../../../../component/select';
import WizardButtonGroup from '../../../../../component/wizardButtonGroup/wizardButtonGroup';
import publicRoutes from '../../../../../core/routes/public';
import { fundraisingCategories } from '../../../../../data/fundraisingCategories';
import { AppState } from '../../../../../store';
import fundraiserMembers from '../../../../../store/modules/fundraiser';
import { FundrasierDTO } from '../../../../../store/modules/fundraiser/model';
import { isSet, useFormInput } from '../../../../../utility/formHelpers';
import { rootLabel, rootNormalInput, section, textField } from '../../styleDefinitions';
import { FundraiserSettingValidationErrors, validateSettings } from './validations';

interface Props {
	classes: any;
	fundraiser: FundrasierDTO;
	handleBack(): void;
	history: History;
	setFundraiserValues(fundraiser: FundrasierDTO): void;
}

const Settings = (props: Props): ReactElement => {
	const { classes, history, fundraiser, handleBack, setFundraiserValues } = props;

	const [errors, setErrorValue] = useState<FundraiserSettingValidationErrors>({});
	const zipCode = useFormInput(fundraiser.zipCode || '');
	const category = useFormInput(fundraiser.category || '');

	function validateAndSaveFundraiser(): void {
		const validateFundraiser: FundrasierDTO = {
			zipCode: zipCode.value,
			category: category.value
		};

		const errors: FundraiserSettingValidationErrors = validateSettings(validateFundraiser);
		if (Object.keys(errors).length === 0) {
			setFundraiserValues(validateFundraiser);
			history.push(publicRoutes.summary.route);
		} else {
			setErrorValue(errors);
		}
	}

	return (
		<div className={classes.section}>
			<SelectWrapped {...category} error={isSet(errors.category)} errorText={errors.category} id={'fundraising-category'}>
				<option value="" disabled>
					Fundraiser category
				</option>
				{fundraisingCategories.map(category => {
					return (
						<option value={category.value} key={category.value}>
							{category.value}
						</option>
					);
				})}
			</SelectWrapped>
			<TextField
				InputProps={{
					classes: {
						root: classes.rootNormalInput,
						input: classes.inputType
					}
				}}
				InputLabelProps={{
					classes: {
						root: classes.rootLabel
					}
				}}
				fullWidth={true}
				{...zipCode}
				className={classes.textField}
				error={isSet(errors.zipCode)}
				helperText={errors.zipCode}
				inputProps={{ maxLength: 5 }}
				label="Fundraiser's primary zip code"
			/>
			<WizardButtonGroup advanceButtonLabel={'Summary'} handleBack={handleBack} handleNext={validateAndSaveFundraiser} />
		</div>
	);
};

const mapStateToProps = (state: AppState): any => {
	return {
		fundraiser: fundraiserMembers.selectors.selectFundraiserData(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>): any => {
	return bindActionCreators(
		{
			setFundraiserValues: fundraiserMembers.actions.setFundraiserValues
		},
		dispatch
	);
};
const customStyles = (theme: Theme): any => ({
	container: {
		margin: '0 auto',
		width: '100%'
	},
	gridContainerSpacing: {
		padding: '12px 0'
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		marginBottom: theme.spacing.unit * 5,
		...textField,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	rootLabel: {
		...rootLabel,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	rootNormalInput: {
		...rootNormalInput,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px'
		}
	},
	section: {
		...section,
		backgroundColor: '#FEF6F1'
	}
});

export default flow([
	withRouter,
	withStyles(customStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
])(Settings);
