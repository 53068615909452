import { Avatar, Theme, withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { ReactElement } from 'react';

interface Props {
	classes: any;
	bannerUrl: string;
	logoUrl?: string;
	logoInitials: string;
}

const DonationHeader = (props: Props): ReactElement => {
	const { classes, logoUrl, logoInitials, bannerUrl } = props;

	const avatar = logoUrl ? (
		<Avatar className={classes.avatarContainer} src={logoUrl} />
	) : (
		<Avatar className={classes.avatarContainer}>{logoInitials}</Avatar>
	);

	return (
		<div
			className={classes.mainHeaderContainer}
			style={{
				background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${bannerUrl}) no-repeat center center`,
				backgroundSize: 'cover'
			}}
		>
			{avatar}
		</div>
	);
};

const styles = (theme: Theme): any => {
	return {
		mainHeaderContainer: {
			position: 'relative',
			width: '100vw',
			height: '290px',
			backgroundSize: 'cover !important',
			backgroundPosition: 'center !important',
			[theme.breakpoints.down('md')]: {
				height: '150px'
			}
		},
		avatarContainer: {
			border: '4px solid white',
			color: '#000',
			background: '#ffffff',
			bottom: '-90px',
			left: '5%',
			width: '180px',
			height: '180px',
			position: 'absolute',
			boxShadow: '0 5px 15px rgba(0, 0, 0, 0.08)',
			[theme.breakpoints.down('md')]: {
				left: 0,
				right: 0,
				marginLeft: 'auto',
				marginRight: 'auto',
				width: '120px',
				height: '120px',
				bottom: '-62px'
			}
		}
	};
};

export default flow([withStyles(styles)])(DonationHeader);
