import { withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import React, { PureComponent, ReactElement } from 'react';

interface Props {
	visible: boolean;
}

class FundraiserDonors extends PureComponent<Props> {
	public render(): ReactElement | null {
		if (!this.props.visible) {
			return null;
		}
		return <div>Donors</div>;
	}
}

const styles = (): any => ({});

export default flow([withStyles(styles)])(FundraiserDonors);
