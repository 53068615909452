import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../..';
import { METHODS } from '../service/model';
import { selectRequestor } from '../service/selectors';
import { activeDonationFailure, activeDonationRequest, activeDonationSuccess } from './actions';
import { ActiveDonation } from './model';
import { selectActiveDonationRequestInFlight } from './selectors';

export const getPublicFundraiser = (publicAccessCode: string): ThunkAction<Promise<void>, AppState, void, Action<any>> => {
	return async (dispatch: Dispatch, getState: () => AppState) => {
		const appState = getState();
		const inFlight = selectActiveDonationRequestInFlight(appState);

		if (inFlight) {
			return Promise.resolve();
		}

		const requestor = selectRequestor(appState);
		dispatch(activeDonationRequest());
		try {
			const requestResponse: AxiosResponse<ActiveDonation> = await requestor.executeApi({
				endpoint: `/donation/${publicAccessCode}/stats`,
				method: METHODS.GET
			});
			dispatch(activeDonationSuccess(requestResponse.data));
		} catch (e) {
			dispatch(activeDonationFailure(e));
		}
	};
};
