import { combineReducers } from 'redux';

import activeDonationMembers from './modules/activeDonation';
import { ActiveDonationState } from './modules/activeDonation/model';
import authMembers from './modules/auth';
import { AuthState } from './modules/auth/model';
import charityMembers from './modules/charity';
import { CharityState } from './modules/charity/model';
import donationMembers from './modules/donation';
import { DonationState } from './modules/donation/model';
import fundraiserMembers from './modules/fundraiser';
import { FundraiserState } from './modules/fundraiser/model';
import organizationMembers from './modules/organization';
import { OrganizationState } from './modules/organization/model';
import publicDonationMembers from './modules/publicDonation';
import { PublicDonationState } from './modules/publicDonation/model';
import publicFundraiserMembers from './modules/publicFundraiser';
import { PublicFundraiserState } from './modules/publicFundraiser/model';
import serviceMembers from './modules/service';
import { ServiceState } from './modules/service/model';

export interface AppState {
	readonly [organizationMembers.constants.STATE_KEY]: OrganizationState;
	readonly [fundraiserMembers.constants.STATE_KEY]: FundraiserState;
	readonly [serviceMembers.constants.STATE_KEY]: ServiceState;
	readonly [authMembers.constants.STATE_KEY]: AuthState;
	readonly [publicFundraiserMembers.constants.STATE_KEY]: PublicFundraiserState;
	readonly [publicDonationMembers.constants.STATE_KEY]: PublicDonationState;
	readonly [charityMembers.constants.STATE_KEY]: CharityState;
	readonly [activeDonationMembers.constants.STATE_KEY]: ActiveDonationState;
	readonly [donationMembers.constants.STATE_KEY]: DonationState;
}

/**
 * While the createStore method ends up creating an AppStore, we want to require that the initial
 * state is primed with a service state.  This helps us create an abstraction of where
 * these reducers is used and how requests can be made.  (Mobile uses different service lib than desktop)
 */
export interface InitialState {
	readonly [serviceMembers.constants.STATE_KEY]: ServiceState;
}

export default combineReducers({
	[organizationMembers.constants.STATE_KEY]: organizationMembers.reducer,
	[fundraiserMembers.constants.STATE_KEY]: fundraiserMembers.reducer,
	[serviceMembers.constants.STATE_KEY]: serviceMembers.reducer,
	[authMembers.constants.STATE_KEY]: authMembers.reducer,
	[publicFundraiserMembers.constants.STATE_KEY]: publicFundraiserMembers.reducer,
	[publicDonationMembers.constants.STATE_KEY]: publicDonationMembers.reducer,
	[charityMembers.constants.STATE_KEY]: charityMembers.reducer,
	[activeDonationMembers.constants.STATE_KEY]: activeDonationMembers.reducer,
	[donationMembers.constants.STATE_KEY]: donationMembers.reducer
});
