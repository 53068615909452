import { formatValueWithCurrency } from '../../../../../utility/currency';

export const DEFAULT_CUSTOM_PERCENT = 0.1;

export const calculationDonationAmount = (formDonationAmount: string, tipPercent: number, customValue: string): number => {
	if (!formDonationAmount || customValue === '.') {
		return 0;
	} else if (tipPercent && tipPercent !== 0) {
		const donationValue = parseInt(formDonationAmount, 10);

		return tipPercent * donationValue;
	} else if (customValue === '') {
		const donationValue = parseInt(formDonationAmount, 10);
		return DEFAULT_CUSTOM_PERCENT * donationValue;
	} else {
		return parseFloat(customValue);
	}
};

export const calculatedPrice = (formDonationAmount: string, tipPercent: number, customValue: string): string => {
	if (!formDonationAmount || customValue === '.') {
		return '';
	}

	const donationValue = parseInt(formDonationAmount, 10);
	const donationAmount = calculationDonationAmount(formDonationAmount, tipPercent, customValue);
	return formatValueWithCurrency(donationAmount + donationValue);
};

export const calculateValueForPercent = (percent: string, value: string): string => {
	const percentDecimal = parseInt(percent, 10) / 100; //expecting values to come in as 5 for .05
	const donationValue = parseInt(value, 10); //values are strings
	return Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(percentDecimal * donationValue);
};
