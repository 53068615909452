export enum TypeAdjustment {
	price,
	number
}

export interface AggregateMetric {
	readonly typeAdjustment: TypeAdjustment;
	readonly today: number;
	readonly allTime: number;
}

export interface AggergateMetricExtra extends AggregateMetric {
	readonly yesterday: number;
	readonly lastSeven: number;
}
