import {
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	InputAdornment,
	TextField,
	Theme,
	Typography,
	withStyles
} from '@material-ui/core';
import flow from 'lodash/flow';
import React, { Fragment, ReactElement, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import Section from '../../../../component/containers/section';
import NumberFormatCustom from '../../../../component/inputNumberFormat/inputNumberFormat';
import { PublicFundraiser } from '../../../../store/modules/publicFundraiser/model';
import { isSet, useFormInput } from '../../../../utility/formHelpers';
import Checkout from './checkout';
import { DonationRequirements } from './formValidations';
import TipCalculation from './tipCalculation/tip';

interface Props {
	classes: any;
	clearPublicFundraiser(): void;
	fundraiser: PublicFundraiser;
	fundraiserId: string;
	getPublicFundraiser(fundraiserKey: string): void;
	hasError: boolean;
	tipValue: number;
}

const DonationSection = (props: Props): ReactElement => {
	const { classes, fundraiser, fundraiserId } = props;

	// State tracking variables
	const buttonEl = useRef<HTMLButtonElement>(null);
	const formDonationAmount = useFormInput('');
	const [formErrors, setFormErrors] = useState<DonationRequirements>({});

	const [anonymousDonation, setAnonDonation] = useState(false);
	const toggleAnonDonation = (): void => {
		setAnonDonation(!anonymousDonation);
	};

	// Methods throughout function
	const initiateCheckoutDialog = (): void => {
		if (!formDonationAmount.value || parseFloat(formDonationAmount.value) <= 0) {
			setFormErrors({ donationAmount: 'Please enter a donation amount' });
			return;
		}
		setFormErrors({});
		if (buttonEl.current) {
			buttonEl.current.click();
		}
	};

	const primaryColor = fundraiser.sitePrimaryColor ? fundraiser.sitePrimaryColor : '';
	const secondaryColor = fundraiser.siteSecondaryColor ? fundraiser.siteSecondaryColor : '';

	const isAuction = fundraiser.auction || false;

	return (
		<Fragment>
			<Section>
				<Fragment>
					<div className={classes.groupContainer}>
						{!isAuction && (
							<Typography variant="h6">
								I would like to make a <strong>one-time</strong> donation for
							</Typography>
						)}
						{isAuction && <Typography variant="h6">Please enter your winning bid amount</Typography>}
					</div>
					<div className={[classes.textContainer, classes.groupContainerNoGutter].join(' ')}>
						<TextField
							label=""
							fullWidth={true}
							placeholder={'1,000'}
							InputLabelProps={{
								classes: {
									root: classes.rootLabel
								}
							}}
							type="tel"
							InputProps={{
								inputComponent: NumberFormatCustom,
								classes: {
									root: classes.rootGoalInput,
									input: classes.inputType
								},
								startAdornment: (
									<InputAdornment position="start">
										<strong>$</strong>
									</InputAdornment>
								)
							}}
							error={isSet(formErrors.donationAmount)}
							helperText={formErrors.donationAmount}
							{...formDonationAmount}
						/>
					</div>
					<div className={classes.groupContainer}>
						<Typography variant="h6">
							to <strong>{fundraiser.title}</strong>
						</Typography>
					</div>
					<div className={[classes.groupContainer, classes.groupContainerMax].join(' ')}>
						<TipCalculation formDonationAmount={formDonationAmount.value} />
					</div>
					{!fundraiser.auction && (
						<div className={classes.groupContainerNoGutter}>
							<div style={{ width: '100%', textAlign: 'center' }}>
								<FormControlLabel
									classes={{
										label: classes.checkmarkLabel
									}}
									control={
										<Checkbox
											checked={anonymousDonation}
											onChange={toggleAnonDonation}
											value="gilad"
											style={{ color: secondaryColor }}
										/>
									}
									label="Hide my name from everyone but the organizer"
								/>
							</div>
						</div>
					)}
					<div className={classes.groupContainer}>
						<Grid container justify="center">
							<Grid item>
								<Button
									style={{ backgroundColor: primaryColor }}
									color={'primary'}
									variant={'contained'}
									className={classes.buttonStyle}
									onClick={initiateCheckoutDialog}
								>
									Enter payment information...
								</Button>
							</Grid>
						</Grid>
					</div>
				</Fragment>
			</Section>
			<div className={classes.contentDivider} style={{ backgroundColor: secondaryColor }}>
				<Section>
					<Grid container justify="center" zeroMinWidth>
						<Grid item className={classes.item}>
							<div className={classes.iconLabel}>About this organization</div>
						</Grid>
					</Grid>
				</Section>
			</div>
			<Section>
				<Grid container>
					<Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
						<img src={fundraiser.smallImage} className={classes.smallImage} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="h4" className={classes.aboutTitle}>
							{fundraiser.title}
						</Typography>
						<ReactMarkdown source={fundraiser.description} />
					</Grid>
				</Grid>
			</Section>
			<Checkout
				buttonReference={buttonEl}
				anonymousDonation={anonymousDonation}
				donationAmount={formDonationAmount.value}
				fundraiserId={fundraiserId}
				logo={fundraiser.logo}
				title={fundraiser.title}
			/>
		</Fragment>
	);
};

const styles = (theme: Theme): any => {
	return {
		aboutTitle: {
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
				paddingTop: '15px'
			}
		},
		smallImage: {
			maxWidth: 350,
			width: '95%'
		},
		headerText: {
			textAlign: 'center'
		},
		groupContainer: {
			padding: '10px 0',
			textAlign: 'center'
		},
		groupContainerMax: {
			marginLeft: 'auto',
			marginRight: 'auto',
			maxWidth: 400,
			backgroundColor: 'rgba(119,196,158,.10)',
			paddingLeft: '10px',
			paddingRight: '10px'
		},
		groupContainerNoGutter: {
			padding: 0,
			textAlign: 'center'
		},
		item: {
			cursor: 'pointer',
			height: '43px',
			paddingTop: '15px',
			paddingLeft: '35px',
			paddingRight: '35px',
			'&:hover': {
				backgroundColor: 'rgba(103,186,140,.2)'
			}
		},
		icon: {
			width: '10px',
			display: 'inline',
			marginRight: '15px',
			[theme.breakpoints.down('sm')]: {
				marginRight: '0'
			}
		},
		iconLabel: {
			display: 'inline'
		},
		contentDivider: {
			marginTop: 30,
			marginBottom: 30,
			backgroundColor: 'rgba(103,186,140,.2)',
			height: 43,
			width: '100%'
		},
		textField: {
			marginLeft: theme.spacing.unit,
			marginRight: theme.spacing.unit,
			width: 300,
			fontSize: '22px'
		},
		textContainer: {
			width: 300,
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		buttonStyle: {
			textTransform: 'none',
			fontSize: '18px'
		},
		rootLabel: {
			fontSize: '25px',
			fontWeight: 300,
			color: '#000000',
			[theme.breakpoints.down('sm')]: {
				fontSize: '18px'
			}
		},
		rootGoalInput: {
			fontWeight: 300,
			fontSize: '54px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '30px'
			}
		},
		rootDateInput: {
			paddingTop: '10px',
			fontWeight: 300,
			fontSize: '24px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '18px'
			}
		},
		inputType: {
			textAlign: 'right'
		},
		checkmarkLabel: {
			fontSize: '1.25rem',
			[theme.breakpoints.down('sm')]: {
				fontSize: 14
			}
		},
		headSection: {
			boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.50)',
			marginBottom: 45
		}
	};
};

export default flow([withStyles(styles)])(DonationSection);
