export const PUBLIC_DONATION_FAILURE = 'publicDonation/PUBLIC_DONATION_FAILURE';
export const PUBLIC_DONATION_REQUEST = 'publicDonation/PUBLIC_DONATION_REQUEST';
export const PUBLIC_DONATION_SUCCESS = 'publicDonation/PUBLIC_DONATION_SUCCESS';
export const PUBLIC_DONATION_CLEAR = 'publicDonation/PUBLIC_DONATION_CLEAR';
export const PUBLIC_DONATION_SET_AMOUNT = 'publicDonation/PUBLIC_DONATION_SET_AMOUNT';
export const PUBLIC_DONATION_SET_TIP = 'publicDonation/PUBLIC_DONATION_SET_TIP';

export default {
	PUBLIC_DONATION_CLEAR,
	PUBLIC_DONATION_FAILURE,
	PUBLIC_DONATION_REQUEST,
	PUBLIC_DONATION_SUCCESS,
	PUBLIC_DONATION_SET_AMOUNT,
	PUBLIC_DONATION_SET_TIP
};
