import { withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
	classes: any;
	children: ReactElement;
}

const Section = (props: Props): ReactElement => {
	return <div className={props.classes.container}>{props.children}</div>;
};

const styles = (): any => {
	return {
		container: {
			maxWidth: '1230px',
			marginLeft: 'auto',
			marginRight: 'auto',
			padding: '0 5px'
		}
	};
};

export default withStyles(styles)(Section);
