import { Theme, Typography, withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from './slide.module.scss';

interface Props {
	classes: any;
	header?: string;
	image: string;
	isCurrent: boolean;
	text?: string;
}

const Slide = (props: Props): ReactElement => {
	const { isCurrent, image, text, header, classes } = props;

	return (
		<li
			aria-hidden={!isCurrent}
			className={styles.slide}
			style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(${image})` }}
		>
			{text && header && (
				<div className={styles.content}>
					<Typography variant="h3" className={classes.contentH3} gutterBottom>
						{header}
					</Typography>
					<Typography variant="h5" className={classes.contentH5} gutterBottom>
						{text}
					</Typography>
					<Typography variant="body1" className={classes.content}>
						Learn more...
					</Typography>
				</div>
			)}
		</li>
	);
};

const customStyles = (theme: Theme): any => {
	return {
		contentH3: {
			color: 'white',
			[theme.breakpoints.up('xs')]: {
				fontSize: '20px'
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: '30px'
			}
		},
		contentH5: {
			color: 'white',
			[theme.breakpoints.up('xs')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: '18px'
			}
		},
		content: {
			color: 'white',
			[theme.breakpoints.up('xs')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: '16px'
			}
		}
	};
};

export default withStyles(customStyles)(Slide);
