import { RequestStatus } from './requestInterface';

export const successStatus: RequestStatus = {
	error: undefined,
	inFlight: false,
	success: true
};

export const requestStatus: RequestStatus = {
	error: undefined,
	inFlight: true,
	success: false
};

export const errorStatus: RequestStatus = {
	error: undefined,
	inFlight: false,
	success: false
};
