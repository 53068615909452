import { AppState } from '../../index';
import { STATE_KEY } from './constants';
import { Organization, OrganizationMetrics, OrganizationState } from './model';

const selectOrganization = (state: AppState): OrganizationState => state[STATE_KEY];
export const selectOrganizationRequestInFlight = (state: AppState): boolean => selectOrganization(state).status.inFlight;
export const selectOrganizationRequestSuccess = (state: AppState): boolean => selectOrganization(state).status.success;
export const selectOrganizationRequestFailed = (state: AppState): boolean => !!selectOrganization(state).status.error;

export const selectOrganizationList = (state: AppState): Organization[] => {
	return Object.values(selectOrganization(state).organizationList);
};

export const selectOrganizationById = (state: AppState, organizationId: string): Organization => {
	return selectOrganization(state).organizationList[organizationId];
};

export const selectOrganizationMetricsById = (state: AppState, organizationId: string): OrganizationMetrics | undefined => {
	const org = selectOrganization(state).organizationList[organizationId];
	if (!org) {
		return undefined;
	}

	return org.metrics;
};

export const selectOrganizationFundraiserKeys = (state: AppState, organizationId: string): string[] => {
	return selectOrganization(state).organizationFundraisers[organizationId] || [];
};
