import { Action } from '../../common/typesInterface';
import * as t from './actionTypes';
import { Organization } from './model';

export const organizationListRequest = (): Action => ({
	type: t.ORGANIZATION_LIST_REQUEST
});

export const organizationListSuccess = (organizations: Organization[]): Action => ({
	type: t.ORGANIZATION_LIST_SUCCESS,
	data: organizations
});

export const organizationListFailure = (error: Error): Action => ({
	type: t.ORGANIZATION_LIST_FAILURE,
	error
});

export const organizationMetricsRequest = (): Action => ({
	type: t.ORGANIZATION_METRICS_REQUEST
});

export const organizationMetricsSuccess = (data: any): Action => ({
	type: t.ORGANIZATION_METRICS_SUCCESS,
	data
});

export const organizationMetricsFailure = (error: Error): Action => ({
	type: t.ORGANIZATION_METRICS_FAILURE,
	error
});

export const clearOrganizationList = (): Action => ({
	type: t.ORGANIZATION_LIST_CLEAR
});
