import { FundrasierDTO } from '../../../../../store/modules/fundraiser/model';
import { isSet } from '../../../../../utility/formHelpers';
import { FundraiserGoalValidationErrors } from './interfaces';

//@todo: Add test cases.

export const validateFundraiser = ({ goal, charityEin }: FundrasierDTO): FundraiserGoalValidationErrors => {
	const errors: FundraiserGoalValidationErrors = {};
	if (!isSet(goal)) {
		errors.goal = 'Please enter a fundraising goal';
	}

	if (!isSet(charityEin)) {
		errors.charity = true;
	}

	return errors;
};
